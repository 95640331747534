import React from "react";
import { ITableDataMapping } from "../../../Model/UI";
import { TableComponent } from "../../TableComponent/TableComponent";
import { TableCell, TableRow } from "@material-ui/core";
// import "./EClaimsApprovers.scss";
import { IEClaimsTab } from "Model/ServerResponse/IEClaimsTab";

interface IEClaimsApproversProps {
  eClaimsApproversTabData: IEClaimsTab | undefined;
}

const EClaimsApprovers: React.FC<IEClaimsApproversProps> = ({ eClaimsApproversTabData }) => {
  const tableHeaderMapping: ITableDataMapping[] = [
    {
      id: "level",
      label: "Level",
    },
    {
      id: "approverName",
      label: "Approver",
    },
    {
      id: "requestDate",
      label: "Received On",
    },
    {
      id: "actionDate",
      label: "Processed On",
    },
  ];

  return (
    <div data-testid="eclaims-approvers-tab" className="eclaims-approvers-tab">
      {tabUI({ eClaimsApproversTabData })}
    </div>
  );

  function tabUI({ eClaimsApproversTabData }: { eClaimsApproversTabData: IEClaimsTab | undefined }) {
    if (eClaimsApproversTabData && eClaimsApproversTabData.data && eClaimsApproversTabData.data.length > 0) {
      return (
        <TableComponent
          tableData={eClaimsApproversTabData.data}
          mappingTableData={tableHeaderMapping}
          getTableRow={getTableApproversRow}
        />
      );
    } else {
      return <div className="no-approvers">No Approvers</div>;
    }
  }

  function getTableApproversRow(index: number, row: any) {
    return (
      <React.Fragment key={index}>
        <TableRow className="spacing"></TableRow>
        <TableRow className="main-row">
          <TableCell className="approval-level">{row.level}</TableCell>
          <TableCell className="approver-name">{row.approver}</TableCell>
          <TableCell>{row.receivedOn}</TableCell>
          <TableCell>{row.processedOn}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
};

export default EClaimsApprovers;
