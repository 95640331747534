import {} from "@material-ui/core";
import { IDefaultUIProp } from "../../../Model/UI";
import * as React from "react";
import { AttachmentCell } from "../../AttachmentList/AttachmentCell";
import {
  IAttachmentData,
  IEClaimsAttachmentData,
  IEClaims
} from "../../../Model/ServerResponse";
import { useState, useEffect } from "react";


import { EC_ATTACHMENT_TAB_ID } from "../EClaimsUtil";
import { EClaimsModal } from "./EClaimsModal";

export interface IEClaimsAttachmentProp extends IDefaultUIProp {
  eClaim: IEClaims;
  eClaimAttachment: IAttachmentData | undefined;
  eClaimAttachmentFunction: (fileID: string, filePath: string) => Promise<IAttachmentData>;
}

export function EClaimsAttachments(props: IEClaimsAttachmentProp) {
    let attachmentsData = props.eClaim.tabs.find(
    (td) => td.tabId === EC_ATTACHMENT_TAB_ID
  );
  let attachmentData = attachmentsData?.data as IEClaimsAttachmentData[];
  let [isAttachmentDownloadable, setisAttachmentDownloadable] = useState(false);

  useEffect(() => {
    if (props.eClaim.isAttachmentDownloadable) {
      setisAttachmentDownloadable(true);
    }
  }, [props.eClaim]);

  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileID, setSelectedFileID] = useState("");

  const handleAttachmentModalClose = () => {
    setAttachmentModalOpen(false);
  };

  function handleAttachmentClick(e: any, fileName: string, fileID: string) {
    if (isAttachmentDownloadable) {
      setAttachmentModalOpen(true);
      setSelectedFilename(fileName);
      setSelectedFileID(fileID);
    }
  }
  
  

  function getAttachmentList() {
    if (attachmentData?.length > 0) {
      return (
        <div data-testid="attachment-list" className="attachment-list">
          {attachmentData.map((e, index) => {
            return (
              <AttachmentCell
                key={e.id}
                onClick={handleAttachmentClick}
                fileID={e.id}
                fileName={e.fileName}
                isDownloadable={isAttachmentDownloadable}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div data-testid="attachment-list" className="no-attachment">
          No Attachments
        </div>
      );
    }
  }

  return (
    <>
      <div data-testid="attachment-modal">
        <div data-testid="attachment-cell">{getAttachmentList()}</div>
        {console.log("attachmentData", attachmentData)}

        <EClaimsModal
          isEClaim
          open={attachmentModalOpen}
          selectedFilename={selectedFilename}
          selectedFileID={selectedFileID}
          eClaimAttachment = {props.eClaimAttachment}
          getAttachmentData={props.eClaimAttachmentFunction}
          onClose={handleAttachmentModalClose}
        />
      </div>
    </>
  );
}
