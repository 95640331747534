import React from 'react';
import { Avatar } from '@material-ui/core';
import Utility from '../../common/utilities';
import styles from './UserDetails.module.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import { UserProfileService } from 'Services';
import { IServerResponse, IUserDetails } from 'Model/ServerResponse';
import { LoggingService } from 'Services/LoggingService';


export interface UserDetailsProps {
    cardId?: any;
    userId?: any;
    userOrganization?: string;
    userCardName?: string;
    token?: any;
    forHeader?: boolean;
    userRole?: any;
}

export interface UserDetailsState {
    name: string;
    photoData: string;
    isReady: boolean;
    isBatchSu: boolean;
}

class UserDetails extends React.Component<UserDetailsProps, UserDetailsState> {

    constructor(props: UserDetailsProps) {
        super(props);
        this.state = {
            name: '',
            photoData: '',
            isReady: false,
            isBatchSu: false,
        }
    }

    private readonly emptyName: string = "Unknown User";

    public componentDidMount = () => {
        this.getUserData();
    }

    public componentDidUpdate = (prevProps: UserDetailsProps) => {
        if (this.props.cardId !== prevProps.cardId) {
            this.getUserData();
        }
    }

    generateInitials = () => {
        const { name } = this.state
        let nameArr: string[] = name.split(" ");
        let initials: string = ""
        if (nameArr.length === 1) {
            initials = nameArr[0][0];
        }
        else {
            initials = nameArr[0][0] + nameArr[nameArr.length - 1][0];
        }

        return initials;

    }

    private addZeroes(value: any) {
        let str = String(value)

        while (str.length < 8) {
            str = '0' + str
        }

        return str;
    }
    private setDefaultState(name: string, isBatchSu: boolean = false) {
        this.setState({
            name,
            photoData: '',
            isReady: true,
            isBatchSu
        });
    }

    private processSystemResponse(systemResponse: IServerResponse<IUserDetails>) {
        // Check if the payload is null
        if (!systemResponse.payload) {
            this.setDefaultState(this.setCardName());
            return;
        }
    
        let name: string = this.setCardName();
        let photoData: string = "";
        let title: string = "";
    
        if (name === this.emptyName && !Utility.isStringEmpty(systemResponse.payload.name)) {
            name = systemResponse.payload.name;
        }
    
        if (!Utility.isStringEmpty(systemResponse.payload.photoData)) {
            photoData = systemResponse.payload.photoData;
        }
    
        if (!Utility.isStringEmpty(systemResponse.payload.title)) {
            title = systemResponse.payload.title;
            if(this.props.userRole) {
                 this.props.userRole(title);
            }
        }
    
        this.setState({ name, photoData, isReady: true, isBatchSu: false });
    }

    private fetchUserDetails(uid: string) {
        let userProfileService = UserProfileService.getInstance();
        let loggingService = LoggingService.getInstance();

        userProfileService?.getUserDetails(uid)
            .then((systemResponse: IServerResponse<IUserDetails>) => {
                if (systemResponse.statusCode === 200 && systemResponse.payload) {
                    this.processSystemResponse(systemResponse);
                } else {
                    this.setDefaultState(this.setCardName());
                }
            })
            .catch((err: any) => {
                this.setDefaultState(this.setCardName());
                // Error logging at BFF
                let errorBody = {
                    "screenId": "Approval User Photo",
                    "errorMessage": err.message,
                    "errorDetails": err.stack,
                }
                loggingService?.logError(errorBody.screenId, errorBody.screenId, errorBody.errorMessage, errorBody.errorDetails);
            });
    }

    private getUserData() {
        if (this.props.userId === undefined) {
            this.setDefaultState(this.setCardName());
            return;
        }

        if (this.props.userId === "BATCH-SU") {
            this.setDefaultState('BATCH-SU', true);
            return;
        }

        let uid = this.props.userId;
        if (!isNaN(parseInt(uid)) && uid.length < 8) {
            uid = this.addZeroes(uid);
        }

        if (!uid) {
            this.setDefaultState(this.setCardName());
            return;
        }

        this.fetchUserDetails(uid);
    }

    setCardName = () => {
        let name = this.emptyName
        if (this.props.userCardName) {
            if (!Utility.isStringEmpty(this.props.userCardName)) {
                name = this.props.userCardName;
            }
        }
        return name;
    }

    callGenerateInitials = () => {
        if (this.props.userId === undefined) {
            return true
        }
        else {
            return (this.state.name !== this.emptyName && this.state.name !== '')
        }
    }

    renderImageContent() {
        const { photoData, isReady, isBatchSu } = this.state;
    
        if (isBatchSu) {
            return <Avatar src="./DashBoard/System_Icn.svg" />;
        } else if (isReady) {
            if (photoData !== '') {
                return <img className={styles.userPhoto} alt='' src={"data:image/png;base64," + photoData} />;
            } else if (this.callGenerateInitials()) {
                return (
                    <div className={styles.userInitials}>
                        {this.generateInitials()}
                    </div>
                );
            } else {
                return <Avatar src="/static/images/avatar/1.jpg" />;
            }
        } else {
            return <Avatar src="/static/images/avatar/1.jpg" />;
        }
    }

    renderNameAndOrganization() {
        const { isReady, name } = this.state;
        const { userOrganization } = this.props;
    
        if (isReady) {
            return (
                <div className={styles.nameOrg}>
                    <span className={styles.name}>{name}</span>
                    <span className={styles.organization}>{userOrganization}</span>
                </div>
            );
        } else {
            return (
                <div className={styles.nameOrg}>
                    <Skeleton className={styles.nameLoader} />
                    <Skeleton className={styles.nameLoader} />
                </div>
            );
        }
    }

    render() {
        const { forHeader } = this.props;
        return (
            <div className={styles.userDetails}>
                <div className={styles.image} >
                    {
                        this.renderImageContent()
                    }
                </div>
                    {
                        !forHeader && this.renderNameAndOrganization()
                    }
            </div>

        );
    }
}

export default UserDetails;