import React from "react";
import {
  IEClaims,
  IEClaimsItemData,
  IEClaimsItemDataDetails,
  IEClaimsItemAdditionalDetails,
  IEClaimsTab,
  IEClaimsItemDataAttendees,
  IEClaimAttendee,
} from "Model/ServerResponse";
import { EC_ITEMS_TAB_ID } from "../EClaimsUtil";
import { IKeyValue, ITableDataMapping } from "Model/UI";
import Utility from "common/utilities";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TableComponent } from "Components2/TableComponent/TableComponent";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { KeyValue } from "Components2/KeyValue/KeyValue";
import { KeyValueList } from "Components2/KeyValueList/KeyValueList";
import { Constants } from "common/constants";
import "./EClaimsDetails.scss";

export interface IEClaimsDetailsProp {
  eClaimsData: IEClaims;
}

export function EClaimsDetails(props: IEClaimsDetailsProp) {
  let eClaimsTabData = props.eClaimsData.tabs.find(
    (tab: IEClaimsTab) => tab.tabId === EC_ITEMS_TAB_ID
  );

  const tableHeaderMapping: ITableDataMapping[] = [
    { id: "expenseType", label: "Expense Type" },
    { id: "description", label: "Description" },
    { id: "transactionAmount", label: "Transaction Amount" },
    { id: "date", label: "Date" },
    { id: "vendor", label: "Vendor" },
    { id: "city", label: "City" },
    { id: "receiptStatus", label: "Receipt Status" },
  ];

  // Define table header mapping for Attendees
  const attendeesHeaderMapping: ITableDataMapping[] = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "company",
      label: "Company",
    },
    {
      id: "type",
      label: "Type",
    },
  ];

  return <div className="po-items-tab">{tabEClaimsData()}</div>;

  function tabEClaimsData() {
    if (eClaimsTabData && !Utility.isArrayEmpty(eClaimsTabData.data)) {
      return (
        <div className="po-optimus-item-rows">
          <TableComponent
            tableData={eClaimsTabData.data}
            mappingTableData={tableHeaderMapping}
            getTableRow={getTableEClaimsItemRow}
          />
        </div>
      );
    } else
      return (
        <div className="no-po-items">
          <p>No Items</p>
        </div>
      );
  }

  function getTableEClaimsItemRow(
    index: number,
    eClaimsItemData: IEClaimsItemData
  ) {
    return (
      <>
        <TableRow>
          <TableRow className="spacing"></TableRow>
        </TableRow>
        <TableRow className="po-items-row-1 po-items-row">
        <TableCell>
            <p className="po-items-row-text-1">
              {eClaimsItemData?.expenseType}
            </p>
          </TableCell>
          
          
          <TableCell>
          <p className="po-items-row-text-1">
              {eClaimsItemData?.description}
            </p>
          </TableCell>
          <TableCell>
            <KeyValue
              className="key-value-1"
              _key={eClaimsItemData?.transactionAmount.toLocaleString()}
              value={eClaimsItemData?.transactionAmountCurrency}
            />
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">
              {Utility.formatDate(eClaimsItemData?.date, Constants.DD_MM_YY)}
            </p>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">{eClaimsItemData?.vendor}</p>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">{eClaimsItemData?.city}</p>
          </TableCell>
          <TableCell>
            <p className="po-items-row-text-1">
              {eClaimsItemData?.receiptStatus}
            </p>
          </TableCell>
          
        </TableRow>
        <TableRow className="detail-row">
          <TableCell colSpan={7}>
            {getDetailSection(
              index,
              eClaimsItemData.details,
              eClaimsItemData.additionalDetails,
              eClaimsItemData.attendees // Now correctly passing the array
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }

  function getDetailSection(
    index: number,
    eClaimsItemDataDetails: IEClaimsItemDataDetails,
    eClaimsItemAdditionalDetails: IEClaimsItemAdditionalDetails,
    eClaimsItemDataAttendeesArray: IEClaimsItemDataAttendees
  ) {
    const eClaimsItemDataAttendees = eClaimsItemDataAttendeesArray[0]; // Get the first item
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="header-content">
            <b>Details</b>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="border-div">
            {getMaterialSection(eClaimsItemDataDetails)}
            {getAdditionalDetailsAccordion(eClaimsItemAdditionalDetails)}
            {getAttendeesAccordion(eClaimsItemDataAttendeesArray)}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getAttendeesAccordion(
    eClaimsItemDataAttendees: IEClaimsItemDataAttendees
  ) {
    if (
      !eClaimsItemDataAttendees ||
      !eClaimsItemDataAttendees.attendees ||
      eClaimsItemDataAttendees.attendees.length === 0
    ) {
      return null;
    }

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="header-content">
            <b>
              Attendees{" "}
            </b>
             
          </div>
          <div className="spacer"></div>
          <span className="headerCount">
                ({eClaimsItemDataAttendees.attendees.length})
              </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="border-div">
            {getAttendeesContentUI(eClaimsItemDataAttendees.attendees)}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  function getAttendeesContentUI(attendees: IEClaimAttendee[]) {
    return <div className="attendees-table">{attendeesTable(attendees)}</div>;
  }

  function attendeesTable(attendees: IEClaimAttendee[]) {
    if (attendees && attendees.length > 0) {
      return (
        <TableComponent
          tableData={attendees}
          mappingTableData={attendeesHeaderMapping}
          getTableRow={getAttendeesTableRow}
        />
      );
    } else {
      return <div className="no-attendees">No Attendees</div>;
    }
  }

  function getAttendeesTableRow(index: number, attendee: IEClaimAttendee) {
    return (
      <TableRow key={index}>
        <TableCell>{attendee.name}</TableCell>
        <TableCell>{attendee.company}</TableCell>
        <TableCell>{attendee.type}</TableCell>
      </TableRow>
    );
  }

  function getMaterialSection(eClaimsItemDataDetails: IEClaimsItemDataDetails) {
    const materialData = getMaterialKeyValueList1(eClaimsItemDataDetails);
    return <KeyValueList data={materialData} />;
  }

  function getAdditionalDetailsSection(
    eClaimsItemAdditionalDetails: IEClaimsItemAdditionalDetails
  ) {
    const additionalDetailsData = getAdditionalDetailsKeyValueList(
      eClaimsItemAdditionalDetails
    );
    return (
      <>
        <KeyValueList data={additionalDetailsData} />
      </>
    );
  }

  function getAdditionalDetailsAccordion(
    eClaimsItemAdditionalDetails: IEClaimsItemAdditionalDetails
  ) {
    if (
      !eClaimsItemAdditionalDetails ||
      Object.keys(eClaimsItemAdditionalDetails).length === 0 || 
      Utility.areAllFieldsEmpty(eClaimsItemAdditionalDetails)
    ) {
      return null;
    }
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="header-content">
            <b>Additional Details</b>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="border-div">
            {getAdditionalDetailsSection(eClaimsItemAdditionalDetails)}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
  function getAdditionalDetailsKeyValueList(
    eClaimsItemAdditionalDetails: IEClaimsItemAdditionalDetails
  ): IKeyValue[] {
    const dataArray: IKeyValue[] = [];

    if (eClaimsItemAdditionalDetails["Type of Entertainment"])
      dataArray.push({
        _key: "Type of Entertainment",
        value: eClaimsItemAdditionalDetails["Type of Entertainment"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Includes Direct Manager"])
      dataArray.push({
        _key: "Includes Direct Manager",
        value: eClaimsItemAdditionalDetails["Includes Direct Manager"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Checkout Date"])
      dataArray.push({
        _key: "Checkout Date",
        value: eClaimsItemAdditionalDetails["Checkout Date"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Number of Nights"] !== undefined)
      dataArray.push({
        _key: "Number of Nights",
        value: eClaimsItemAdditionalDetails["Number of Nights"].toString(),
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["SLB Preferred Vendor"])
      dataArray.push({
        _key: "SLB Preferred Vendor",
        value: eClaimsItemAdditionalDetails["SLB Preferred Vendor"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Reason For Not Using Preferred Vendor"])
      dataArray.push({
        _key: "Reason For Not Using Preferred Vendor",
        value:
          eClaimsItemAdditionalDetails["Reason For Not Using Preferred Vendor"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Booked by Agent/OBT"])
      dataArray.push({
        _key: "Booked by Agent/OBT",
        value: eClaimsItemAdditionalDetails["Booked by Agent/OBT"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["User Comments"])
      dataArray.push({
        _key: "User Comments",
        value: eClaimsItemAdditionalDetails["User Comments"],
        seperator: ":",
      });

    if (
      eClaimsItemAdditionalDetails["Is Total Amount Include Meals"] !==
      undefined
    )
      dataArray.push({
        _key: "Is Total Amount Include Meals",
        value: eClaimsItemAdditionalDetails["Is Total Amount Include Meals"]
          ? "Yes"
          : "No",
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Sub Type"])
      dataArray.push({
        _key: "Sub Type",
        value: eClaimsItemAdditionalDetails["Sub Type"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Number of Rental Days"] !== undefined)
      dataArray.push({
        _key: "Number of Rental Days",
        value: eClaimsItemAdditionalDetails["Number of Rental Days"].toString(),
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Client Name"])
      dataArray.push({
        _key: "Client Name",
        value: eClaimsItemAdditionalDetails["Client Name"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Client Contact Person"])
      dataArray.push({
        _key: "Client Contact Person",
        value: eClaimsItemAdditionalDetails["Client Contact Person"],
        seperator: ":",
      });

    // New fields for Taxi
    if (eClaimsItemAdditionalDetails["From Location"])
      dataArray.push({
        _key: "From Location",
        value: eClaimsItemAdditionalDetails["From Location"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["To Location"])
      dataArray.push({
        _key: "To Location",
        value: eClaimsItemAdditionalDetails["To Location"],
        seperator: ":",
      });

    // New fields for Meals Per Diem
    if (eClaimsItemAdditionalDetails["Start Date"])
      dataArray.push({
        _key: "Start Date",
        value: eClaimsItemAdditionalDetails["Start Date"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["End Date"])
      dataArray.push({
        _key: "End Date",
        value: eClaimsItemAdditionalDetails["End Date"],
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Number of Days/Hours"] !== undefined)
      dataArray.push({
        _key: "Number of Days/Hours",
        value: eClaimsItemAdditionalDetails["Number of Days/Hours"].toString(),
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Rate Daily/Hourly"] !== undefined)
      dataArray.push({
        _key: "Rate Daily/Hourly",
        value: eClaimsItemAdditionalDetails["Rate Daily/Hourly"].toString(),
        seperator: ":",
      });

    if (eClaimsItemAdditionalDetails["Nature of Expenses"])
      dataArray.push({
        _key: "Nature of Expenses",
        value: eClaimsItemAdditionalDetails["Nature of Expenses"],
        seperator: ":",
      });

    return dataArray;
  }

  function getMaterialKeyValueList1(
    eClaimsItemDataDetails: IEClaimsItemDataDetails
  ): IKeyValue[] {
    let dataArray: IKeyValue[] = [];
    if (eClaimsItemDataDetails.type)
      dataArray.push({
        _key: "Type",
        value: eClaimsItemDataDetails.type,
        seperator: ":",
      });
    if (eClaimsItemDataDetails.city)
      dataArray.push({
        _key: "City",
        value: eClaimsItemDataDetails.city,
        seperator: ":",
      });
    if (
      eClaimsItemDataDetails.noOfAttendees &&
      eClaimsItemDataDetails.type === "Group"
    )
      dataArray.push({
        _key: "No. Of Attendees",
        value: eClaimsItemDataDetails.noOfAttendees,
        seperator: ":",
      });
    if (eClaimsItemDataDetails.paymentType)
      dataArray.push({
        _key: "Payment Type",
        value: eClaimsItemDataDetails.paymentType,
        seperator: ":",
      });
    if (eClaimsItemDataDetails.amountApproved)
      dataArray.push({
        _key: "Amount Approved",
        value: eClaimsItemDataDetails.amountApproved,
        seperator: ":",
      });
    if (eClaimsItemDataDetails.receipts)
      dataArray.push({
        _key: "Receipts",
        value: eClaimsItemDataDetails.receipts,
        seperator: ":",
      });
    if (eClaimsItemDataDetails.personalExpense)
      dataArray.push({
        _key: "Personal Expense",
        value: eClaimsItemDataDetails.personalExpense,
        seperator: ":",
      });
    if (eClaimsItemDataDetails.description)
      dataArray.push({
        _key: "Description",
        value: eClaimsItemDataDetails.description,
        seperator: ":",
      });

    return dataArray;
  }
}
