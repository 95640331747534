import { SearchFilterToolbar } from "Components2/SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import React, { useEffect } from "react";
import { ListView } from "Components2/ListView/ListView";
import {EClaimsCard} from "Components2/EClaims/EClaimsCard/EClaimsCard";
import { ISystemListViewBaseProp } from "Model/UI";
import { useSelector } from "react-redux";
import { IEClaims } from "Model/ServerResponse";
import { RequestStatus } from "Model/Enums";

import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  eClaimsInitialState,
  IsTabletViewSelector,
} from "Store/EClaims/EClaimsSlice";

import { useAppDispatch } from "Store/store";
import "./EClaimsListView.scss";

export interface IEClaimsListViewProp<T> extends ISystemListViewBaseProp<T> { }

export function EClaimsListView(props: IEClaimsListViewProp<IEClaims>) {
    let appDispatch = useAppDispatch();

    let supportMulti = useSelector(SupportMultiSelector);
  
    let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
    let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);
  
    let selectedApproval = useSelector(SelectedApprovalSelector);
    let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
    let subSystems = useSelector(SubSystemsSelector);
    let totalCountForFilter = useSelector(TotalCountForFilterSelector);
    let pendingApprovals = useSelector(PendingApprovalsSelector);
  
    let searchQuery = useSelector(SearchQuerySelector);
    let isAsc = useSelector(IsAscSelector);
    let selectedSubSystem = useSelector(SelectedSubSystemSelector);
  
    let isTabletView = useSelector(IsTabletViewSelector);
  
    useEffect(() => {
      appDispatch(resetSystemSliceState(eClaimsInitialState));
    }, []);
  
    //Set up data
    useEffect(() => {
      appDispatch(setSelectedApproval(null));
      appDispatch(GetPendingApprovals());
    }, [searchQuery, isAsc, selectedSubSystem]);

    return (
        <div className="eclaims-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="Expense Report"
        error={pendingApprovalsStatus === RequestStatus.Failed}
        totalCount={totalCountForFilter}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>
      {listUI()}
    </div>
    );

    function getRowUI(data: IEClaims, multiSelectUI? )  {
        return (
            <EClaimsCard
              supportMulti={supportMulti}
              multiSelectUI={multiSelectUI}
              eclaims={data}
            ></EClaimsCard>
        )
      }
    
      function listUI()  {
        if (pendingApprovalsStatus === RequestStatus.Failed) {
          return errorUI();
        }
    
        return (
          <>
            <ListView
              className="eclaims-list"
              totalCount={totalCountForFilter}
              dataList={pendingApprovals}
              selectedData={selectedApproval}
              multiSelectedData={multiSelectedApprovals}
              loading={pendingApprovalsStatus === RequestStatus.Loading}
              supportMulti={supportMulti}
              getRowUI={getRowUI}
              getUniqueDataIdentifier={getUniqueDataIndentifier}
              onSelectChange={handleSelectChange}
              onMultiSelectChange={handleMultiSelectChange}
              onFromPositionChange={handleFromPositionChange}
              getEmptyIndicatorUI={emptyIndicatorUI}
              isTabletView={isTabletView}
            ></ListView>
    
            {multiSelectedApprovals?.length > 0 && actionContainer()}
          </>
        );
      }
    
      function emptyIndicatorUI()  {
        return (
          <div className="message-container">
            <Message1
              title="No approvals."
              imgSrc="CardAssets/NoPendingRequests.svg"
            ></Message1>
          </div>
        );
      }
    
      function errorUI()  {
        if (pendingApprovalsError) {
          return (
            <div className="message-container">
              <Message1
                title={pendingApprovalsError.message}
                subTitle={pendingApprovalsError.description}
                imgSrc={'CardAssets/error.svg'}
              ></Message1>
            </div>
          );
        }
        return <></>;
      }

      function handleRefresh(_e: any) {
        //Reset all state
        appDispatch(resetSystemSliceState(eClaimsInitialState));
        //Get approvals
        appDispatch(GetPendingApprovals());
      }
    
      function handleSearchChange(_e: any, _query: string) {
        appDispatch(setSearchQuery(_query));
      }
    
      function handleSortChange(_e: any, _isAsc: boolean) {
        appDispatch(setIsAsc(_isAsc));
      }
    
      function handleSubSystemChange(_e: any, _subSystem: any) {
        appDispatch(setSelectedSubSystem(_subSystem));
      }
    
      function getUniqueDataIndentifier(_data: IEClaims): string {
        //Return unique identifier
        return _data.id.toString();
      }
    
      function handleFromPositionChange(_fromPostion: number): void {
        appDispatch(setFromPosition(_fromPostion));
        appDispatch(GetPendingApprovals());
      }
    
      function handleMultiSelectChange(_e: any, _values: any) {
        appDispatch(setMultiSelectedApprovals(_values));
      }
    
      function handleSelectChange(_e: any, _value: any) {
        appDispatch(setSelectedApproval(_value));
      }
    
      function actionContainer()  {
        if (props.getActionsUI) {
          return (
            <div className="action-container">
              {props.getActionsUI(true, multiSelectedApprovals as IEClaims[])}
            </div>
          );
        }
        return <></>;
      }
    }


