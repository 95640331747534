import React from "react";
import { Grid } from "@material-ui/core";
import { KeyValue } from "../../KeyValue/KeyValue";
import UserDetails from "../../UserDetails/UserDetails";
import { IKeyValue, ICardTemplateBaseProp } from "../../../Model/UI";

import "./Card9.scss";
import Utility from "common/utilities";

export interface ICard9Prop extends ICardTemplateBaseProp {
  label1: string;
  label2: string;
  label3: string;
  label4: string;
  keyValue1: IKeyValue;
  keyValue2: IKeyValue;
  keyValue3: IKeyValue;

}
/*
UI placement:

    label1          keyValue1
    label2
    label3
    keyValue2 keyValue3
    userDetails    
                    label4
 */

export function Card9(props: ICard9Prop) {
  function getCardUI() {
    return (
      <div
        className={Utility.getClassNamesFromProps("card-9", props.className)}
      >
        {/* 1st row */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="row-1"
        >
          {props.supportMulti ? (
            <div data-testid="multiSelectUI">{props.multiSelectUI}</div>
          ) : (
            <div className="dot"></div>
          )}
          <p className="label-1">{props.label1}</p>
          <div className="spacer"></div>
          <KeyValue
            className="key-value-1"
            _key={props.keyValue1._key}
            value={props.keyValue1.value}
          ></KeyValue>
        </Grid>

        {/* 2nd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-2"
        >
          <p className="label-2">{props.label2}</p>
        </Grid>

        {/* 3rd row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-3"
        >
          <p className="label-3">{props.label3}</p>
        </Grid>

        {/* 4th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-4"
        >
          <KeyValue
            className="key-value-2"
            _key={props.keyValue2._key}
            value={props.keyValue2.value}
            seperator={":"}
          ></KeyValue>
          <KeyValue
            className="key-value-3"
            _key={props.keyValue3._key}
            value={props.keyValue3.value}
            seperator={":"}
          ></KeyValue>
        </Grid>

        {/* 5th row */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-5"
        >
            <UserDetails
              userId={props.userDetails.userId}
              userCardName={props.userDetails.userCardName}
              userOrganization={props.userDetails.userOrganization}
            ></UserDetails>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="row-6"
        >
          <div className="spacer"></div>
          <div className="col-1">
            <p className="label-4">{props.label4}</p>
          </div>
        </Grid>
      </div>
    );
  }

  return getCardUI();
}
