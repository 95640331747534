import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import VariablesJson from "../../../configVariables.json";
import { models, Report, Embed, service } from "powerbi-client";
import { ServicesUtility } from "Services/servicesUtility";

export interface IPODSOHExcessProps {
  plantCode: string | number;
  stockMaterialNumber: string[];
}

export function PODSOHExcess(props: IPODSOHExcessProps) {
  let [errorMessage, setErrorMessage] = React.useState<string>("");
  let reportObj: Report;
  const [report, setReport] = useState<Report>();

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    ["loaded", function () {}],
    [
      "rendered",
      function () {
        getfilters();
        getPageVisuals();
      },
    ],
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          if (
            event?.detail?.errorCode == "403" ||
            event?.detail?.errorCode == "401"
          ) {
            const msg =
              "Cannot see the content of this report because you do not have required permissions";
            setErrorMessage(msg);
          } else if (event?.detail?.errorCode) {
            setErrorMessage(event?.detail?.message);
          }
          console.log("PowerBi Error", event);
        }
      },
    ],
  ]);

  async function getPageVisuals() {
    if (reportObj != undefined) {
      reportObj.getActivePage().then((activePage) => {
        console.log("Active Page: " + activePage.name);
        activePage.getVisuals().then((visuals) => {
          console.log("Visuals Count: " + visuals.length);
          console.log("Visuals: " + visuals);
          for (const v of visuals) {
            if (
              v.name === VariablesJson.Variables.PowerBIConfigPO.visualName ||
              v.name === VariablesJson.Variables.PowerBIConfigDHO.visualName
            ) {
              // Your logic for specific visuals (if needed)
            }
            console.log("Visual Title: " + v.title);
            console.log("Visual Name: " + v.name);
            console.log("Visual Layout: " + v.layout);
          }
        });
      });
    }
  }

  async function getfilters() {
    console.log("getfilters()");
    if (reportObj != undefined) {
      await reportObj.getFilters();
    }
  }

  const getConfiguration = async () => {
    let token = await ServicesUtility.getPowerBiToken();
    console.log("PowerBI Token", token);

    setDhoReport({
      ...dhoReport,
      embedUrl: VariablesJson.Variables.PowerBIConfigDHO.embedUrl,
      accessToken: token,
    });
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  const [dhoReport, setDhoReport] = useState<models.IVisualEmbedConfiguration>({
    type: "visual",
    embedUrl: undefined,
    tokenType: models.TokenType.Aad,
    accessToken: undefined,
    pageName: VariablesJson.Variables.PowerBIConfigDHO.pageName,
    visualName: VariablesJson.Variables.PowerBIConfigDHO.visualName,
    filters: [
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "Query1",
          column:
            VariablesJson.Variables.PowerBIConfigDHO.TargetColumnPlantCode,
        },
        operator: "In",
        values: [props.plantCode],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      },
      {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "Query1",
          column:
            VariablesJson.Variables.PowerBIConfigDHO.TargetColumnStockMaterial,
        },
        operator: "In",
        values: props.stockMaterialNumber,
        filterType: models.FilterType.Basic,
        requireSingleSelection: true,
      },
    ],
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
    },
  });

  return (
    <div>
      <PowerBIEmbed
        cssClassName="power-bi-report"
        embedConfig={dhoReport}
        eventHandlers={eventHandlersMap}
        getEmbeddedComponent={(embedObject: Embed) => {
          console.log(
            `Embedded object of type "${embedObject.embedtype}" received for DHO Report`
          );
          setReport(embedObject as Report);
        }}
      />
    </div>
  );
}
