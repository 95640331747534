import React from "react";
import { IEClaims } from "Model/ServerResponse";
import { Header1 } from "Components2/HeaderTemplates/Header1/Header1";
import { ISystemHeaderBaseProp } from "Model/UI";   

import "./EClaimsHeader.scss";
import Utility from "common/utilities";
import { Constants } from "common/constants";

export interface IEClaimsHeaderData extends ISystemHeaderBaseProp {
  eclaims: IEClaims;
}

export function EClaimsHeader(props: IEClaimsHeaderData) {
  function getHeaderUI(){
    return (
      <Header1
      label1={props.eclaims.approvalSystemId}
      label2={Utility.formatDate(props.eclaims.submitDate, Constants.DD_MM_YY)}
      label3={props.eclaims.title}
      keyValue1={{
        _key: props.eclaims.totalClaimedAmount.toLocaleString(),
        value: props.eclaims.totalClaimedCurrency,
      }}
      userDetails={{
        userId: props.eclaims.requestorID,
        userCardName: props.eclaims.requestor,
        userOrganization: props.eclaims.requestorJobRole,
      }}
        className="eclaims-header"
      ></Header1>
    );
  }

  return getHeaderUI();
}