import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../Model/Enums";
import { IAppState } from "../../Store/store";
import { getPendingApprovals1Thunk, getApprovalDetails1Thunk, postDeleteApprovals1Thunk, deleteApprovals1Thunk, systemSlice1Reducer, ISystemSlice1State } from "../../Store/SystemTemplate/SystemSlice1";


export const eClaimsInitialState: ISystemSlice1State = {
    pendingApprovalsStatus: RequestStatus.Idle,
    pendingApprovalsError: null,
    selectedApprovalDetailStatus: RequestStatus.Idle,
    selectedApprovalDetailError: null,
    supportMulti: false,
    selectedApproval: null,
    selectedApprovalDetails: null,
    multiSelectedApprovals: [],
    searchQuery: "",
    isAsc: false,
    selectedSubSystem: null,
    fromPosition: 0,
    subSystems: [],
    totalCountForFilter: 0,
    pendingApprovals: [],
    isTabletView: false,
};

const EClaimsSlice = createSlice({
    name: "EClaims",
    initialState: eClaimsInitialState,
    reducers: systemSlice1Reducer
});

export const GetPendingApprovals = getPendingApprovals1Thunk(eClaimsInitialState, EClaimsSlice, "Claim", "Claim");
export const GetApprovalDetails = getApprovalDetails1Thunk(EClaimsSlice, "Claim", "Claim");
export const PostDeleteApprovals = postDeleteApprovals1Thunk(EClaimsSlice, "Claim");
export const DeleteApprovals = deleteApprovals1Thunk(EClaimsSlice, "Claim", "Claim");

//Action

export const {
    resetSystemSliceState,
    setPendingApprovalsStatus,
    setSelectedApproval,
    setSelectedApprovalDetails,
    setMultiSelectedApprovals,
    setSearchQuery,
    setIsAsc,
    setSelectedSubSystem,
    setFromPosition,
    setSubSystems,
    setTotalCountForFilter,
    setPendingApprovals,
    addToPendingApprovals,
    reCalculateSelectedApproval,
    setIsTabletView,
} = EClaimsSlice.actions;

//Selector

export const EClaimsSelector = (state: IAppState) => state.Claim;
export const PendingApprovalsStatusSelector = createSelector(
    EClaimsSelector,
    (details) => details?.pendingApprovalsStatus
);

export const PendingApprovalsErrorSelector = createSelector(
    EClaimsSelector,
    (details) => details?.pendingApprovalsError
);

export const SelectedApprovalDetailStatusSelector = createSelector(
    EClaimsSelector,
    (details) => details?.selectedApprovalDetailStatus
);

export const SelectedApprovalDetailErrorSelector = createSelector(
    EClaimsSelector,
    (details) => details?.selectedApprovalDetailError
);

export const SupportMultiSelector = createSelector(
    EClaimsSelector,
    (details) => details?.supportMulti
);

export const SelectedApprovalSelector = createSelector(
    EClaimsSelector,
    (details) => details?.selectedApproval
);

export const SelectedApprovalDetailsSelector = createSelector(
    EClaimsSelector,
    (details) => details?.selectedApprovalDetails
);

export const MultiSelectedApprovalsSelector = createSelector(
    EClaimsSelector,
    (details) => details?.multiSelectedApprovals
);

export const SearchQuerySelector = createSelector(
    EClaimsSelector,
    (details) => details?.searchQuery
);

export const IsAscSelector = createSelector(
    EClaimsSelector,
    (details) => details?.isAsc
);

export const SelectedSubSystemSelector = createSelector(
    EClaimsSelector,
    (details) => details?.selectedSubSystem
);

export const FromPositionSelector = createSelector(
    EClaimsSelector,
    (details) => details?.fromPosition
);

export const SubSystemsSelector = createSelector(
    EClaimsSelector,
    (details) => details?.subSystems
);

export const TotalCountForFilterSelector = createSelector(
    EClaimsSelector,
    (details) => details?.totalCountForFilter
);

export const PendingApprovalsSelector = createSelector(
    EClaimsSelector,
    (details) => details?.pendingApprovals
);


export const IsTabletViewSelector = createSelector(
    EClaimsSelector,
    (details) => details?.isTabletView
);

export const EClaimsReducer = EClaimsSlice.reducer;    




