import {} from "@material-ui/core";
import { IDefaultUIProp } from "../../../Model/UI";
import * as React from "react";
import { AttachmentCell } from "../../AttachmentList/AttachmentCell";
import {
  IAttachmentData,
  IPOAttachmentData,
  IServerResponse,IPO
} from "../../../Model/ServerResponse";
import { AttachmentModal } from "../../Modals/AttachmentModal/AttachmentModal";
import { useState, useEffect } from "react";
import { AttachmentService } from "../../../Services";
import utilities from "../../../common/utilities";

import { PO_ATTACHMENTS_TAB_ID } from "../POUtil";

export interface IPOAttachmentProp extends IDefaultUIProp {
  po: IPO;
}

export function POAttachment(props: IPOAttachmentProp){
  let attachmentsData = props.po.tabs.find(
    (td) => td.tabId === PO_ATTACHMENTS_TAB_ID
  );
  let attachmentData = attachmentsData?.data as IPOAttachmentData[];
  let [isAttachmentDownloadable, setisAttachmentDownloadable] = useState(false);
  useEffect(() => {
    if (props.po.isAttachmentDownloadable) {
      setisAttachmentDownloadable(true);
    }
  }, [props.po]);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileID, setselectedFileID] = useState("");

  const handleAttachmentModalClose = () => {
    setAttachmentModalOpen(false);
  };

  function handleAttachmentClick(e: any, fileName: string, fileID: string) {
    if (isAttachmentDownloadable) {
      setAttachmentModalOpen(true);
      setSelectedFilename(fileName);
      setselectedFileID(fileID);
    }
  }

  let attachmentService = AttachmentService.getInstance();

  async function getAttachmentData(
    fileID: string,
    filePath: string
  ): Promise<IAttachmentData> {
    return attachmentService
      .getAttachmentData(
        `api/approvals/${
          utilities.getSystemActivityMapping(props.po.approvalSystemId)[1]
        }/${utilities.getSystemActivityMapping(props.po.approvalSystemId)[0]}/${
          props.po.id
        }/attachments/${fileID}`
      )
      .then((attachmentResponse: IServerResponse<IAttachmentData>) => {
        return attachmentResponse.payload!;
      });
  }

  function getAttachmentList(){
    if (attachmentsData ) {
      return (
        <div data-testid="attachment-list" className="attachment-list">
          {attachmentData.map((e, index) => {
            return (
              <AttachmentCell
                key={e.id}
                onClick={handleAttachmentClick}
                fileID={e.id}
                fileName={e.fileName}
                isDownloadable={isAttachmentDownloadable}
              />
            );
          })}
        </div>
      );
    } else
      return (
        <div data-testid="attachment-list" className="no-attachment">
          {" "}
          No Attachments{" "}
        </div>
      );
  }

  return (
    <>
      <div data-testid="attachment-modal">
        <div data-testid="attachment-cell">{getAttachmentList()}</div>


        <AttachmentModal
          open={attachmentModalOpen}
          selectedFilename={selectedFilename}
          selectedFileID={selectedFileID}
          getAttachmentData={getAttachmentData}
          onClose={handleAttachmentModalClose}
        />
      </div>
    </>
  );
}
