import {
  createSlice,
  AsyncThunk,
  createAsyncThunk,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit";
import { ISubSystem } from "Model/UI";
import { IAppState } from "Store/store";
import {
  ISystem,
  IFailedTakeAction,
  IServerResponse,
  IServerResponseError,
  ITakeActionRequest,
  ITakeActionResponse,
} from "../../Model/ServerResponse";

import { RequestStatus, ScreenSize } from "../../Model/Enums";
import {
  SetApprovalOperationCount,
  SetApprovalSystemCount,
} from "Store/UserSystemProfile/UserSystemSlice";
import Utility from "common/utilities";
import { ApprovalService, ServicesUtility } from "Services";
import { setScreenLoader, SetSplashProgress } from "Store/Common/CommonSlice";
import { LoggingService } from "Services/LoggingService";
import { Constants } from "common/constants";

type ApprovalOperationKeyType =
  | "Cart"
  | "PR"
  | "Timesheet"
  | "PO"
  | "Trip"
  | "QuestChange"
  | "FDP"
  | "Plan"
  | "Travel"
  | "Watershed"
  | "Quote"
  | "SRM"
  | "Claim";

export interface ISystemSlice1State {
  //Loading vars
  pendingApprovalsStatus: RequestStatus;
  forwardApprovalStatus?: RequestStatus;
  pendingApprovalsError: IServerResponseError | null;

  selectedApprovalDetailStatus: RequestStatus;
  selectedApprovalDetailError: IServerResponseError | null;

  //List vars
  supportMulti: boolean;
  selectedApproval: ISystem | null;
  selectedApprovalDetails: ISystem | null;
  multiSelectedApprovals: ISystem[];

  //Search toolbar vars
  searchQuery: string;
  isAsc: boolean;
  selectedSubSystem: ISubSystem | null;
  fromPosition: number;

  //FilterTollbar vars
  filterQuery?: string;

  //SubSytem list
  subSystems: ISubSystem[];

  //Total count
  totalCountForFilter: number;

  //Data source for list
  pendingApprovals: ISystem[];

  //Tablet, Mobile view
  isTabletView: boolean;

}

export function setSubSystemsForPendingApprovals1Thunk(
  initState: ISystemSlice1State,
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType
): AsyncThunk<void, ISubSystem[], {}> {
  return createAsyncThunk(
    "SystemSlice1/setSubSystemsForPendingApprovals1Thunk",
    async (subSystems: ISubSystem[], thunkApi): Promise<void> => {
      let loggingService = LoggingService.getInstance();
      try {
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let dispatch = thunkApi.dispatch;
        let selfActions = slice.actions;

        let isBaseApiCall = isBaseApi(state, initState);
        subSystems.forEach((ss) => {
          let sysActivityMapping = Utility.getSystemActivityMapping(
            ss.systemName
          );
          //If filters are in initial state, that means its a base api call, which gives us total count
          if (isBaseApiCall) {
            dispatch(
              SetApprovalSystemCount({
                approvalSystemKey: sysActivityMapping[1],
                count: ss.count,
              })
            );
          }
        });

        dispatch(selfActions.setSubSystems(subSystems));
      } catch (err) {
        await loggingService.logError(
          "SystemSlice1 : setSubSystemsForPendingApprovals1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
      }
    }
  );
}

export function setTotalCountForPendingApprovals1Thunk(
  initState: ISystemSlice1State,
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType,
  approvalOperationKey: string
): AsyncThunk<void, ISubSystem[], {}> {
  return createAsyncThunk(
    "SystemSlice1/setTotalCountForPendingApprovals1Thunk",
    async (subSystems: ISubSystem[], thunkApi): Promise<void> => {
      let loggingService = LoggingService.getInstance();
      try {
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let dispatch = thunkApi.dispatch;
        let selfActions = slice.actions;

        let isBaseApiCall = isBaseApi(state, initState);
        let count = Utility.sumOfSubSytemsCount(subSystems);

        //If filters are in initial state, that means its a base api call, which gives us total count
        if (isBaseApiCall) {
          dispatch(
            SetApprovalOperationCount({
              approvalOperationKey: approvalOperationKey,
              count: count,
            })
          );

        }

        dispatch(selfActions.setTotalCountForFilter(count));
      } catch (err) {
        await loggingService.logError(
          "SystemSlice1 : setTotalCountForPendingApprovals1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
      }
    }
  );
}

export function getPendingApprovals1Thunk(
  initState: ISystemSlice1State,
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType,
  approvalOperationKey: string
): AsyncThunk<void, void, {}> {
  return createAsyncThunk(
    "SystemSlice1/getPendingApprovals1Thunk",
    async (_, thunkApi): Promise<void> => {
      let loggingService = LoggingService.getInstance();
      try {
        let dispatch = thunkApi.dispatch;
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let selfActions = slice.actions;
        let approvalService = ApprovalService.getInstance();
        const PageSize: number = Constants.PageSize;

        dispatch(selfActions.setPendingApprovalsStatus(RequestStatus.Loading));
        dispatch(selfActions.setApprovalDetailError(null));

        let result = await approvalService.getPendingApprovals<ISystem>(
          `api/approvals/${approvalOperationKey}?searchString=${
            state.searchQuery
          }&sortOrder=${
            state.isAsc ? "ASC" : "DSC"
          }&items=${PageSize}&systemName=${
            state.selectedSubSystem ? state.selectedSubSystem.systemName : ""
          }&fromPosition=${state.fromPosition}`
        );

        // Parse response for error
        let error = ServicesUtility.ParseResponseForErrors(result);
        //Set status & error for api request
        if (error) {
          dispatch(selfActions.setPendingApprovalsError(error));
          dispatch(selfActions.setPendingApprovalsStatus(RequestStatus.Failed));
        } else {
          if (
            result.payload?.displayCount &&
            ((result.payload?.displayCount.length == 1 &&
              result.payload?.displayCount[0]?.errorMessage) ||
              (result.payload?.displayCount.length > 1 &&
                result.payload?.displayCount[0]?.errorMessage &&
                result.payload?.displayCount[1]?.errorMessage))
          ) {
            const sourceSystemError: IServerResponseError = {
              message: "Source system not responding",
              description: "Unable to load Approvals from source system.",
            };
            dispatch(selfActions.setPendingApprovalsError(sourceSystemError));
            dispatch(
              selfActions.setPendingApprovalsStatus(RequestStatus.Failed)
            );
            dispatch(selfActions.setApprovalDetailError(sourceSystemError));
            dispatch(selfActions.setApprovalDetailStatus(RequestStatus.Failed));
          } else {
            dispatch(selfActions.setPendingApprovalsError(null));
            dispatch(
              selfActions.setPendingApprovalsStatus(RequestStatus.Success)
            );
          }
        }

        //Update splash state
        if (isBaseApi(state, initState)) {
          dispatch(
            SetSplashProgress({ taskKey: approvalOperationKey, error: error })
          );
        }

        let data = result.payload;
        if (data) {
          let _subSystems = Utility.convertDisplayCountToSubSytem(
            data.displayCount
          );

          let setSubSystemsForPendingApprovals1 =
            setSubSystemsForPendingApprovals1Thunk(
              initState,
              slice,
              approvalOperationSliceKey
            );
          let totalCountForPendingApprovals1 =
            setTotalCountForPendingApprovals1Thunk(
              initState,
              slice,
              approvalOperationSliceKey,
              approvalOperationKey
            );

          dispatch(setSubSystemsForPendingApprovals1(_subSystems));
          dispatch(totalCountForPendingApprovals1(_subSystems));
          dispatch(selfActions.addToPendingApprovals(data.pendingApprovals));
          dispatch(selfActions.reCalculateSelectedApproval());
          dispatch(selfActions.reCalculateMultiSelectedApproval());
        }
      } catch (err) {
        await loggingService.logError(
          "SystemSlice1 : getPendingApprovals1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
      }
    }
  );
}

export function getApprovalDetails1Thunk(
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType,
  approvalOperationKey: string
): AsyncThunk<void, void, {}> {
  return createAsyncThunk(
    "SystemSlice1/getApprovalDetails1Thunk",
    async (_, thunkApi): Promise<void> => {
      let loggingService = LoggingService.getInstance();
      let dispatch = thunkApi.dispatch;
      let selfActions = slice.actions;
      try {
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let approvalService = ApprovalService.getInstance();

        dispatch(selfActions.setApprovalDetailStatus(RequestStatus.Loading));
        dispatch(selfActions.setApprovalDetailError(null));

        if (state.selectedApproval) {
          let approvalSystemKey = Utility.getSystemActivityMapping(
            state.selectedApproval.approvalSystemId
          )[1];
          let result = await approvalService.getPendingApprovalDetails<ISystem>(
            `api/approvals/${approvalOperationKey}/details?system=${approvalSystemKey}&approvalId=${state.selectedApproval.id}`
          );
          let data = result.payload;
          state = (thunkApi.getState() as IAppState)[approvalOperationSliceKey];
          if (
            data &&
            state.selectedApproval &&
            data.pendingApproval.id === state.selectedApproval.id
          ) {
            dispatch(
              selfActions.setSelectedApprovalDetails(data.pendingApproval)
            );
            dispatch(selfActions.setApprovalDetailError(null));
            dispatch(
              selfActions.setApprovalDetailStatus(RequestStatus.Success)
            );
          } else {
            dispatch(selfActions.setSelectedApprovalDetails(null));
            // Parse response for error
            let error = ServicesUtility.ParseResponseForErrors(result);
            //Set status & error for api request
            if (error) {
              dispatch(selfActions.setApprovalDetailError(error));
              dispatch(
                selfActions.setApprovalDetailStatus(RequestStatus.Failed)
              );
            }
          }
        }
      } catch (err) {
        await loggingService?.logError(
          "SystemSlice1 : getApprovalDetails1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
        dispatch(selfActions.setSelectedApprovalDetails(null));
        const errorobj = {
          message: "Unable to fetch the data",
          description: "Please refresh the page or try again later.",
        };
        dispatch(selfActions.setApprovalDetailError(errorobj));
        dispatch(selfActions.setApprovalDetailStatus(RequestStatus.Failed));
      }
    }
  );
}

export function postDeleteApprovals1Thunk(
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType
): AsyncThunk<
  {
    passedApprovals: ISystem[];
    failedTakeActions: IFailedTakeAction[];
  },
  {
    isApprove: boolean;
    isMulti: boolean;
    comment: string;
    getRequestBody?: (approval: ISystem, comment: string) => any;
  },
  {}
> {
  return createAsyncThunk(
    "SystemSlice1/postDeleteApprovals1Thunk",
    async (
      {
        isApprove,
        isMulti,
        comment,
        getRequestBody,
      }: {
        isApprove: boolean;
        isMulti: boolean;
        comment: string;
        getRequestBody?: (approval: ISystem, comment: string) => any;
      },
      thunkApi
    ): Promise<{
      passedApprovals: ISystem[];
      failedTakeActions: IFailedTakeAction[];
    }> => {
      let loggingService = LoggingService.getInstance();
      try {
        let dispatch = thunkApi.dispatch;
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let approvalService = ApprovalService.getInstance();

        let approvals: ISystem[] = [];

        if (isMulti) {
          approvals = state.multiSelectedApprovals;
        } else if (state.selectedApproval) {
          approvals = [state.selectedApproval];
        }

        //form take actions (old-implementation)
        // let takeActionsRequests: ITakeActionRequest[] = approvals.map((a) => {
        //   if (getRequestBody) {
        //     return getRequestBody(a, comment);
        //   } else {
        //     return {
        //       //Test code : remove the test-id
        //       approvalId: a.id.toString(), // + " test-id",
        //       comment: comment,
        //     };
        //   }
        // });

        let takeActionsRequests: ITakeActionRequest[] = [];
        for (let item of approvals) {
          if (getRequestBody) {
            let x = getRequestBody(item, comment);
            if (x instanceof Array) {
              takeActionsRequests = takeActionsRequests.concat(x);
            } else {
              takeActionsRequests.push(x);
            }
          } else {
            takeActionsRequests.push({
              approvalId: item.id.toString(), // + " test-id",
              comment: comment,
            });
          }
        }

        //Start screen loader
        dispatch(setScreenLoader(true));

        let failedActions: IFailedTakeAction[] = [];
        const tar: any = takeActionsRequests;
        const systemAcitivtyMapping = Utility.getSystemActivityMapping(
          approvals[0].approvalSystemId
        );
        let result: IServerResponse<ITakeActionResponse> | null = null;
        if (isApprove) {
          result = await approvalService.approvePendingApprovals(
            systemAcitivtyMapping[1],
            systemAcitivtyMapping[0],
            tar
          );
        } else {
          result = await approvalService.rejectPendingApprovals(
            systemAcitivtyMapping[1],
            systemAcitivtyMapping[0],
            tar
          );
        }

        if (result && result.statusCode === 200 && result.payload) {
          if (result.payload.failedActions.length > 0) {
            failedActions = failedActions.concat(result.payload.failedActions);
          }
        } else {
          failedActions.push({
            approvalId: tar.approvalId,
            message: result.errorMessage,
            detail: result.errorMessage,
            reason: result.errorMessage,
          });
        }
        //identify passed approvals
        let passedApprovals: ISystem[] = [];
        approvals.forEach((approval) => {
          let matches = failedActions.findIndex(
            (fa) => fa.approvalId === approval.id.toString()
          );
          if (matches === -1) {
            passedApprovals.push(approval);
          }
        });

        //Stop screen loader
        dispatch(setScreenLoader(false));

        return {
          passedApprovals: passedApprovals,
          failedTakeActions: failedActions,
        };
      } catch (err) {
        await loggingService.logError(
          "SystemSlice1 : postDeleteApprovals1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
        return {
          passedApprovals: [],
          failedTakeActions: [],
        };
      }
    }
  );
}

export function postForwardApprovals1Thunk(
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType
): AsyncThunk<
  {
    passedApprovals: ISystem[];
    failedTakeActions: IFailedTakeAction[];
  },
  {
    isForward: boolean;
    isMulti: boolean;
    comment: string;
    approver?: string;
    getRequestBody?: (
      approval: ISystem,
      comment: string,
      approver?: string
    ) => any;
  },
  {}
> {
  return createAsyncThunk(
    "SystemSlice1/postDeleteApprovals1Thunk",
    async (
      {
        isForward,
        isMulti,
        comment,
        approver,
        getRequestBody,
      }: {
        isForward: boolean;
        isMulti: boolean;
        comment: string;
        approver?: string;
        getRequestBody?: (
          approval: ISystem,
          comment: string,
          approver?: string
        ) => any;
      },
      thunkApi
    ): Promise<{
      passedApprovals: ISystem[];
      failedTakeActions: IFailedTakeAction[];
    }> => {
      let loggingService = LoggingService.getInstance();
      try {
        let dispatch = thunkApi.dispatch;
        let selfActions = slice.actions;
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let approvalService = ApprovalService.getInstance();

        let approvals: ISystem[] = [];

        if (isMulti) {
          approvals = state.multiSelectedApprovals;
        } else if (state.selectedApproval) {
          approvals = [state.selectedApproval];
        }

        //form take actions (old-implementation)
        // let takeActionsRequests: ITakeActionRequest[] = approvals.map((a) => {
        //   if (getRequestBody) {
        //     return getRequestBody(a, comment);
        //   } else {
        //     return {
        //       //Test code : remove the test-id
        //       approvalId: a.id.toString(), // + " test-id",
        //       comment: comment,
        //     };
        //   }
        // });

        let takeActionsRequests: ITakeActionRequest[] = [];
        for (let item of approvals) {
          if (getRequestBody) {
            let x = getRequestBody(item, comment, approver);
            if (x instanceof Array) {
              takeActionsRequests = takeActionsRequests.concat(x);
            } else {
              takeActionsRequests.push(x);
            }
          } else {
            takeActionsRequests.push({
              approvalId: item.id.toString(), // + " test-id",
              comment: comment,
            });
          }
        }

        //Start screen loader
        dispatch(setScreenLoader(true));
        dispatch(selfActions.setForwardApprovalStatus(RequestStatus.Loading));

        let failedActions: IFailedTakeAction[] = [];
        const tar: any = takeActionsRequests;
        const systemAcitivtyMapping = Utility.getSystemActivityMapping(
          approvals[0].approvalSystemId
        );
        let result: IServerResponse<ITakeActionResponse> | null = null;
        if (isForward) {
          result = await approvalService.forwardPendingApprovals(
            systemAcitivtyMapping[1],
            systemAcitivtyMapping[0],
            tar
          );
        }
        if (result && result.statusCode === 200 && result.payload) {
          if (result.payload.failedActions.length > 0) {
            failedActions = failedActions.concat(result.payload.failedActions);
          }
        } else {
          if (result)
            failedActions.push({
              approvalId: tar.approvalId,
              message: result.errorMessage,
              detail: result.errorMessage,
              reason: result.errorMessage,
            });
        }
        //identify passed approvals
        let passedApprovals: ISystem[] = [];
        approvals.forEach((approval) => {
          let matches = failedActions.findIndex(
            (fa) => fa.approvalId === approval.id.toString()
          );
          if (matches === -1) {
            passedApprovals.push(approval);
          }
        });

        //Stop screen loader
        dispatch(setScreenLoader(false));
        dispatch(selfActions.setForwardApprovalStatus(RequestStatus.Success));

        return {
          passedApprovals: passedApprovals,
          failedTakeActions: failedActions,
        };
      } catch (err) {
        let dispatch = thunkApi.dispatch;
        let selfActions = slice.actions;
        dispatch(selfActions.setForwardApprovalStatus(RequestStatus.Failed));
        await loggingService.logError(
          "SystemSlice1 : postDeleteApprovals1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
        return {
          passedApprovals: [],
          failedTakeActions: [],
        };
      }
    }
  );
}

export function deleteApprovals1Thunk(
  slice: Slice<ISystemSlice1State, typeof systemSlice1Reducer>,
  approvalOperationSliceKey: ApprovalOperationKeyType,
  approvalOperationKey: string
): AsyncThunk<void, ISystem[], {}> {
  return createAsyncThunk(
    "SystemSlice1/deleteApprovals1Thunk",
    async (approvalsToDelete: ISystem[], thunkApi): Promise<void> => {
      let loggingService = LoggingService.getInstance();
      try {
        let dispatch = thunkApi.dispatch;
        let state = (thunkApi.getState() as IAppState)[
          approvalOperationSliceKey
        ];
        let userSystemState = (thunkApi.getState() as IAppState).UserSystem;
        let selfActions = slice.actions;

        let totalCount =
          userSystemState.approvalOperationsMap[approvalOperationKey].count;

        //** Update total count **
        //Subtract the approvals to delete from total count
        if (navigator.onLine) {
          let _totalCount = totalCount - approvalsToDelete.length;
          let _totalCountForFilter =
            state.totalCountForFilter - approvalsToDelete.length;

          //Set in User system state (master)
          dispatch(
            SetApprovalOperationCount({
              approvalOperationKey: approvalOperationKey,
              count: _totalCount,
            })
          );
          //Set in self state
          dispatch(selfActions.setTotalCountForFilter(_totalCountForFilter));

          //** Update approval system counts **
          let approvalSystemKeys =
            userSystemState.approvalOperationsMap[approvalOperationKey]
              .approvalSystems;
          approvalSystemKeys.forEach((ask, index) => {
            let askInApprovalsToDelete = approvalsToDelete.filter((atd) => {
              let activitySystemMapping = Utility.getSystemActivityMapping(
                atd.approvalSystemId
              );
              let systemName = activitySystemMapping[1];

              return systemName === ask;
            });
            let subSystemToReplace: ISubSystem | undefined =
              state.subSystems.find((ss: any) => {
                let activitySystemMapping = Utility.getSystemActivityMapping(
                  ss.systemName
                );
                let systemKey = activitySystemMapping[1];
                if (systemKey === ask) {
                  return true;
                }
                return false;
              });
            let countToUpdate =
              userSystemState.approvalSystemsMap[ask].count -
              askInApprovalsToDelete.length;
            if (subSystemToReplace) {
              //Update in master
              dispatch(
                SetApprovalSystemCount({
                  approvalSystemKey: ask,
                  count: countToUpdate,
                })
              );
              //update in self
              dispatch(
                selfActions.replaceSubSystem({
                  ...subSystemToReplace,
                  count: countToUpdate,
                })
              );
            }
          });

          //** Update approvals list **
          let _approvals = state.pendingApprovals.filter(
            (pa: any) =>
              approvalsToDelete.findIndex((atd) => atd.id === pa.id) === -1
          );
          dispatch(selfActions.setPendingApprovals([..._approvals]));

          //** Re-calculate selected & multi-selected approvals **
          dispatch(selfActions.reCalculateSelectedApproval());
          dispatch(selfActions.reCalculateMultiSelectedApproval());

        }
      } catch (err) {
        await loggingService.logError(
          "SystemSlice1 : deleteApprovals1Thunk",
          approvalOperationSliceKey,
          JSON.stringify(err, Object.getOwnPropertyNames(err)),
          JSON.stringify(err, Object.getOwnPropertyNames(err))
        );
      }
    }
  );
}

function isBaseApi(
  state: ISystemSlice1State,
  initState: ISystemSlice1State
): boolean {
  return (
    state.fromPosition === initState.fromPosition &&
    state.searchQuery === initState.searchQuery &&
    state.isAsc === initState.isAsc &&
    state.selectedSubSystem === initState.selectedSubSystem
  );
}

export const systemSlice1Reducer = {
  resetSystemSliceState(
    state: ISystemSlice1State,
    action: PayloadAction<ISystemSlice1State>
  ) {
    let initState = action.payload;
    state.pendingApprovalsStatus = initState.pendingApprovalsStatus;
    state.forwardApprovalStatus = initState.forwardApprovalStatus;
    state.selectedApproval = initState.selectedApproval;
    state.multiSelectedApprovals = initState.multiSelectedApprovals;
    state.searchQuery = initState.searchQuery;
    state.filterQuery = initState.filterQuery;
    state.isAsc = initState.isAsc;
    state.selectedSubSystem = initState.selectedSubSystem;
    state.fromPosition = initState.fromPosition;
    state.subSystems = initState.subSystems;
    state.totalCountForFilter = initState.totalCountForFilter;
    state.pendingApprovals = initState.pendingApprovals;
  },
  setPendingApprovalsStatus(
    state: ISystemSlice1State,
    action: PayloadAction<RequestStatus>
  ) {
    state.pendingApprovalsStatus = action.payload;
  },
  setForwardApprovalStatus(
    state: ISystemSlice1State,
    action: PayloadAction<RequestStatus>
  ) {
    state.forwardApprovalStatus = action.payload;
  },
  setPendingApprovalsError(
    state: ISystemSlice1State,
    action: PayloadAction<IServerResponseError | null>
  ) {
    state.pendingApprovalsError = action.payload;
  },
  setApprovalDetailStatus(
    state: ISystemSlice1State,
    action: PayloadAction<RequestStatus>
  ) {
    state.selectedApprovalDetailStatus = action.payload;
  },
  setApprovalDetailError(
    state: ISystemSlice1State,
    action: PayloadAction<IServerResponseError | null>
  ) {
    state.selectedApprovalDetailError = action.payload;
  },
  setSelectedApproval(
    state: ISystemSlice1State,
    action: PayloadAction<ISystem | null>
  ) {
    state.selectedApproval = action.payload;
  },
  setSelectedApprovalDetails(
    state: ISystemSlice1State,
    action: PayloadAction<ISystem | null>
  ) {
    state.selectedApprovalDetails = action.payload;
  },
  setMultiSelectedApprovals(
    state: ISystemSlice1State,
    action: PayloadAction<ISystem[]>
  ) {
    state.multiSelectedApprovals = action.payload;
  },
  setSearchQuery(state: ISystemSlice1State, action: PayloadAction<string>) {
    if (state.searchQuery !== action.payload) {
      state.totalCountForFilter = 0;
      state.pendingApprovals = [];
      state.fromPosition = 0;
      state.multiSelectedApprovals = [];
    }
    state.searchQuery = action.payload;
  },
  setFilterQuery(state: ISystemSlice1State, action: PayloadAction<string>) {
    state.filterQuery = action.payload;
  },
  setIsAsc(state: ISystemSlice1State, action: PayloadAction<boolean>) {
    if (state.isAsc !== action.payload) {
      state.totalCountForFilter = 0;
      state.pendingApprovals = [];
      state.fromPosition = 0;
      state.multiSelectedApprovals = [];
    }
    state.isAsc = action.payload;
  },
  setSelectedSubSystem(
    state: ISystemSlice1State,
    action: PayloadAction<ISubSystem | null>
  ) {
    if (state.selectedSubSystem !== action.payload) {
      state.totalCountForFilter = 0;
      state.pendingApprovals = [];
      state.fromPosition = 0;
      state.multiSelectedApprovals = [];
    }
    state.selectedSubSystem = action.payload;
  },
  setFromPosition(state: ISystemSlice1State, action: PayloadAction<number>) {
    state.fromPosition = action.payload;
  },
  setSubSystems(
    state: ISystemSlice1State,
    action: PayloadAction<ISubSystem[]>
  ) {
    state.subSystems = action.payload;
  },
  replaceSubSystem(
    state: ISystemSlice1State,
    action: PayloadAction<ISubSystem>
  ) {
    let ssToReplace = action.payload;
    state.subSystems.forEach((ss, index) => {
      if (ss.systemName === ssToReplace.systemName) {
        state.subSystems[index] = ssToReplace;
      }
    });
  },
  setTotalCountForFilter(
    state: ISystemSlice1State,
    action: PayloadAction<number>
  ) {
    state.totalCountForFilter = action.payload;
  },
  setPendingApprovals(
    state: ISystemSlice1State,
    action: PayloadAction<ISystem[]>
  ) {
    state.pendingApprovals = [...action.payload];
  },
  addToPendingApprovals(
    state: ISystemSlice1State,
    action: PayloadAction<ISystem[]>
  ) {
    const tempState = [...state.pendingApprovals];
    const payload = [...action.payload];
    const newUnique = payload.filter(
      (item) => !tempState.some((existingItem) => existingItem.id === item.id)
    );

    state.pendingApprovals = [...state.pendingApprovals, ...newUnique];
  },
  reCalculateSelectedApproval(state: ISystemSlice1State) {
    if (state.pendingApprovals.length > 0 && !state.isTabletView) {
      let selectedApprovalExistInPA: boolean =
        state.pendingApprovals.findIndex(
          (pa) => pa.id === state.selectedApproval?.id
        ) >= 0;
      if (selectedApprovalExistInPA) {
        return;
      }
      state.selectedApproval = state.pendingApprovals[0];
    } else {
      state.selectedApproval = null;
    }
  },
  reCalculateMultiSelectedApproval(state: ISystemSlice1State) {
    let approvalsToRetainInMulti = state.multiSelectedApprovals.filter(
      (msa) => state.pendingApprovals.findIndex((pa) => pa.id === msa.id) >= 0
    );
    state.multiSelectedApprovals = [...approvalsToRetainInMulti];
  },
  setIsTabletView(state: ISystemSlice1State, action: PayloadAction<boolean>) {
    state.isTabletView = action.payload;
  },

};
