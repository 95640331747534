import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "Model/UI";
import * as React from "react";
import "./UserGuide.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VariablesJson from "../../../configVariables.json";
import { CssTextField } from "../SwitchProfile/SwitchProfile";
import Utility from "common/utilities";

export interface IUserGuide extends IDefaultUIProp {
  open: boolean;
  onClose: any;
}

export function UserGuide(props: IUserGuide)  {
  let [open, setOpen] = React.useState<boolean>(false);
  let [searchQuery, setSearchQuery] = React.useState<string>("");

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  let systems: string[] = [
    "ApproveBuddy",
    "Cart",
    "DLP - Digital Lift Planning",
    "eClaims",
    "eJourney",
    "eQuote",
    "JRA - Job Readiness Assessment",
    "PR",
    "PO - Purchase Order",
    "Pretrip",
    "Quest",
    "Shopping Cart",
    "Task Sheet",
    "Time Sheet"
  ];

  function isError(searchQuery: string): boolean {
    if (searchQuery && searchQuery.length > 0) {
      for (const system of systems) {
        if (system.toLowerCase().includes(searchQuery.toLowerCase())) {
          return false; // Partial match found, return false
        }
      }
      return true; // No partial match found, return true
    }
    return false;
  }
  

  function getUserGuides() {
    let userGuides: any = [];
    let systems: any = [
      "ApproveBuddy",
      "Cart",
      "DLP - Digital Lift Planning",
      "eClaims",
      "eJourney",
      "eQuote",
      "JRA - Job Readiness Assessment",
      "PR",
      "PO - Purchase Order",
      "Pretrip",
      "Quest",
      "Shopping Cart",
      "Task Sheet",
      "Time Sheet",
    ];

    systems.map((element: string) => {
      let data: any = VariablesJson.Variables;
      let userManualLink: any = VariablesJson.Variables.usermanual;
      userGuides.push({
        systemName: element,
        systemUrl: data[element],
        userManualLink: userManualLink[element],
      });
    });
    if (searchQuery) {
      userGuides = userGuides.filter((guide: any) =>
        guide.systemName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return userGuides;
  }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        className="user-guide-profile-modal"
      >
        <div className="title-text1-container">
          <p className="titleText">User Guide</p>
          <div className="spacer"></div>
          <IconButton onClick={handleClose}>
            <CancelIcon className="closeButton"></CancelIcon>
          </IconButton>
        </div>

        <DialogContent className="user-guide-content">
          <Divider className="divider-1" variant="fullWidth" />

          <div className="title-text-container">
            <p className="label">How To Use</p>
            <div className="spacer"></div>
            {/* <Button className="refresh-btn" onClick={handleClick}> */}
            {/* Click Here
            </Button> */}
            <div className="search-bar">
              <CssTextField
                error={isError(searchQuery)}
                helperText={
                  isError(searchQuery) ? "Please enter a valid System Name" : ""
                }
                label="Search system"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                variant="outlined"
                fullWidth
                size="small"
              />
            </div>
          </div>

          {/* <p className="label-3">System Approval - Tutorial Videos</p> */}

          {getUserGuides().map((system: any, index: number) => {
            return (
                <div key={Utility.createUniqueId(system, index)} className="user-guide-content">
                  {system.systemUrl ? (
                    <TableRow className="detail-row">
                      <TableCell colSpan={7}>
                        {getSystemNameAccordion(
                          system.systemName,
                          system.systemUrl,
                          system.userManualLink,
                          index
                        )}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </div>
            );
          })}
        </DialogContent>
      </Dialog>
  );

  function handleClose(e: any): void {
    setOpen(false);
    props.onClose();
    setSearchQuery("");
  }

  function getSystemNameAccordion(
    systemName: string,
    systemUrl: string,
    userManualLink: string,
    index: number
  )  {
    {
      if (!systemName.includes("PO")) {
        return (
          <>
            <Accordion defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="sys-name">{systemName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="title-text-container">
                  <p className="label">User Guide</p>
                  <div className="spacer"></div>
                  <Button className="refresh-btn" onClick={handleClick}>
                    View Document
                  </Button>
                </div>

                {!systemName.includes("ApproveBuddy") && (
                  <>
                    <Divider className="divider-2" variant="fullWidth" />
                    <div className="video-section">
                      <p className="label-3">Video</p>
                      <div className="video">
                        <CardMedia
                          component="video"
                          width="100%"
                          height="100%"
                          src={systemUrl}
                          controls
                          preload="metadata"
                        />
                      </div>
                    </div>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        );
      } else if (systemName.includes("PO - Purchase Order")) {
        return (
          <>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="sys-name">{systemName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="title-text-container">
                  <p className="label">User Guide</p>
                  <div className="spacer"></div>
                  <Button className="refresh-btn" onClick={handleClick}>
                    View Document
                  </Button>
                </div>
                <Divider className="divider-2" variant="fullWidth" />
                <p className="label-3">Videos</p>
                <Grid className="video-area">
                  <React.Fragment>
                    <Grid className="video-container">
                      <Typography className="sys-name">PO - ITT</Typography>
                      <div className="video">
                        <CardMedia
                          component="video"
                          width="100%"
                          height="100%"
                          src={
                            VariablesJson.Variables["PO - Purchase Order"][
                              "PO-ITT"
                            ]
                          }
                          controls
                          preload="metadata"
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                  <React.Fragment>
                    <Grid className="video-container">
                      <Typography className="sys-name">PO - Optimus</Typography>
                      <div className="video">
                        <CardMedia
                          component="video"
                          width="100%"
                          height="100%"
                          src={
                            VariablesJson.Variables["PO - Purchase Order"][
                              "PO-Optimus"
                            ]
                          }
                          controls
                          preload="metadata"
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        );
      }
      return <></>;
    }
    function handleClick() {
      if (!systemName.includes("PO")) {
        window.open(userManualLink, "_blank");
      } else {
        window.open(
          VariablesJson.Variables["PO - Purchase Order"]["PO-UserManual"],
          "_blank"
        );
      }
    }
  }
}
