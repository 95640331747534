import { SearchFilterToolbar } from "../../../Components2/SearchFilterToolbar/SearchFilterToolbar";
import { Message1 } from "../../../Components2/MessageTemplates/Message1/Message1";
import React, { useEffect, useState } from "react";
import { ListView } from "../../ListView/ListView";
import { POCard } from "../POCard/POCard";
import { ISystemListViewBaseProp } from "../../../Model/UI";
import { useSelector } from "react-redux";
import {
  IsAscSelector,
  MultiSelectedApprovalsSelector,
  PendingApprovalsSelector,
  SearchQuerySelector,
  SelectedApprovalSelector,
  SelectedSubSystemSelector,
  setIsAsc,
  setMultiSelectedApprovals,
  setFromPosition,
  setSearchQuery,
  setSelectedApproval,
  setSelectedSubSystem,
  SubSystemsSelector,
  TotalCountForFilterSelector,
  GetPendingApprovals,
  resetSystemSliceState,
  PendingApprovalsStatusSelector,
  PendingApprovalsErrorSelector,
  SupportMultiSelector,
  poSliceInitialState,
  IsTabletViewSelector,
  
} from "../../../Store/PO/POSlice";
import { IPO, IServerResponse } from "../../../Model/ServerResponse";
import "./POListView.scss";
import { useAppDispatch } from "../../../Store/store";
import { RequestStatus } from "../../../Model/Enums";
import { useLocation } from "react-router-dom";
import { SetSplashProgressBar } from "../../../Store/Common/CommonSlice";
import { SystemSelectionService } from "Services";
import AlertDialog from "Components2/Alert/AlertDialog";
import { OptimusCard } from "../POCard/OptimusCard";
import VariablesJson from "../../../configVariables.json";
import { CircularProgress, Divider } from "@material-ui/core";
export interface IPOListViewProp<T> extends ISystemListViewBaseProp<T> {}

export function POListView(props: IPOListViewProp<IPO>) {
  let appDispatch = useAppDispatch();
  const id = new URLSearchParams(useLocation()?.search).get("ponumber");
  let supportMulti = useSelector(SupportMultiSelector);

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let pendingApprovalsError = useSelector(PendingApprovalsErrorSelector);

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);
  let subSystems = useSelector(SubSystemsSelector);
  let totalCountForFilter = useSelector(TotalCountForFilterSelector);
  let pendingApprovals = useSelector(PendingApprovalsSelector);
  let searchQuery = useSelector(SearchQuerySelector);
  let isAsc = useSelector(IsAscSelector);
  let selectedSubSystem = useSelector(SelectedSubSystemSelector);
  let isTabletView = useSelector(IsTabletViewSelector);
  const [isLoading, setIsLoading] = useState(false);  // State to manage loading indicator
  const POITT = "PO-ITT";
  console.log("selectedSubSystem", selectedSubSystem?.systemName);
  useEffect(() => {
    appDispatch(resetSystemSliceState(poSliceInitialState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id) {
      let systemservice = SystemSelectionService.getInstance();
      systemservice
        .resetSpecificSystemCache("PO", "ITT")
        .then((res: IServerResponse<any>) => {
          appDispatch(setSearchQuery(id));
        });
    }
  }, [id]);

  //Set up data
  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    if (id) {
      if (searchQuery) {
        appDispatch(GetPendingApprovals());
        appDispatch(SetSplashProgressBar(false));
      }
    } else {
      appDispatch(GetPendingApprovals());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isAsc, selectedSubSystem]);
  const shouldDisplay =
    subSystems?.find((x) => x.systemName === "PO-ITT")?.isEnabled === true;
  const isSelectedSystemPOITT =
    !selectedSubSystem?.systemName || selectedSubSystem.systemName === "PO-ITT";

  return (
    <div className="po-list-container">
      <SearchFilterToolbar
        multiSelectedCount={multiSelectedApprovals?.length}
        title="PO"
        searchPlaceHolderText="Vendor | Requester | Plant Code | PO No."
        error={pendingApprovalsStatus === RequestStatus.Failed}
        totalCount={totalCountForFilter}
        count={pendingApprovals?.length}
        subSystems={subSystems}
        powerBILink={VariablesJson.Variables.poHistory_PowerBIlink}
        powerBIReportText={
          shouldDisplay && isSelectedSystemPOITT ? "Approval History" : ""
        }
        loading={pendingApprovalsStatus === RequestStatus.Loading}
        onRefresh={handleRefresh}
        onSearch={handleSearchChange}
        onSort={handleSortChange}
        onSubSystemChange={handleSubSystemChange}
      ></SearchFilterToolbar>

      {listUI()}
    </div>
  );

  function getRowUI(data: IPO, multiSelectUI?){
    return data.approvalSystemId === POITT ? (
      <POCard
        supportMulti={supportMulti}
        multiSelectUI={multiSelectUI}
        po={data}
      ></POCard>
    ) : (
      <OptimusCard
        supportMulti={supportMulti}
        multiSelectUI={multiSelectUI}
        po={data}
      ></OptimusCard>
    );
  }

  function listUI(){
    if (pendingApprovalsStatus === RequestStatus.Failed) {
      return errorUI();
    }

    return (
      <>
        <ListView
          className="po-list"
          totalCount={totalCountForFilter}
          dataList={pendingApprovals}
          selectedData={selectedApproval}
          multiSelectedData={multiSelectedApprovals}
          loading={pendingApprovalsStatus === RequestStatus.Loading}
          supportMulti={supportMulti}
          getRowUI={getRowUI}
          getUniqueDataIdentifier={getUniqueDataIndentifier}
          onSelectChange={handleSelectChange}
          onMultiSelectChange={handleMultiSelectChange}
          onFromPositionChange={handleFromPositionChange}
          getEmptyIndicatorUI={emptyIndicatorUI}
          isTabletView={isTabletView}
        ></ListView>

        {multiSelectedApprovals?.length > 0 && actionContainer()}
        {poAlertDialogSystemAccess()}
      </>
    );
  }

  function emptyIndicatorUI(){
    return (
      <div className="message-container">
        <Message1
          title="No approvals."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI() {
    if (pendingApprovalsError) {
      return (
        <div className="message-container">
          <Message1
            title={pendingApprovalsError.message}
            imgSrc="CardAssets/error.svg"
            subTitle={`${pendingApprovalsError.description} Check if your SAP account is active.`}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function handleRefresh(e: any) {
    //Reset all state
    appDispatch(resetSystemSliceState(poSliceInitialState));
    //Get approvals
    appDispatch(GetPendingApprovals());
  }

  function handleSearchChange(e: any, _query: string) {
    appDispatch(setSearchQuery(_query));
  }

  function handleSortChange(e: any, _isAsc: boolean) {
    appDispatch(setIsAsc(_isAsc));
  }

  function handleSubSystemChange(e: any, _subSystem: any) {
    appDispatch(setSelectedSubSystem(_subSystem));
  }

  function getUniqueDataIndentifier(_data: IPO): string {
    //Return unique identifier
    return _data.id.toString();
  }

  function handleFromPositionChange(_fromPostion: number): void {
    appDispatch(setFromPosition(_fromPostion));
    appDispatch(GetPendingApprovals());
  }

  function handleMultiSelectChange(e: any, _values: any) {
    appDispatch(setMultiSelectedApprovals(_values));
  }

  function handleSelectChange(e: any, _value: any) {
    appDispatch(setSelectedApproval(_value));
  }

  function getParamValue(url: string, paramName: string) {
    // Remove any preceding hash mark (#) from the URL
    url = url.split("#")[1];
    // Split the URL by the question mark (?) to get the query string
    const queryString = url.split("?")[1];
    // If no query string is present, return null
    if (!queryString) {
      return null;
    }
    // Split the query string by the ampersand (&) to get the parameters
    const parameters = queryString.split("&");
    // Iterate over the parameters to find the specified parameter and its value
    for (const parameter of parameters) {
      const [rawName, rawValue] = parameter.split("=");
      const paramNameFromURL = decodeURIComponent(rawName);

      // If the parameter name matches the specified name, return its value
      if (paramNameFromURL === paramName) {
        return decodeURIComponent(rawValue);
      }
    }

    // If the parameter is not found, return null
    return null;
  }

  function getAlertContentUI() {

    function resetPOCacheAPI() {
      setIsLoading(true);  // Start loading
      let systemservice = SystemSelectionService.getInstance();
      systemservice
        .resetSpecificSystemCache("PO", "ITT")
        .then((_res) => {
          const paramValue = getParamValue(window.location.href, "ponumber");
          if (paramValue) {
            appDispatch(setSearchQuery(paramValue));
          }
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((error) => {
          console.error("Failed to reset cache: ", error);
          alert("Failed to refresh the system. Please try again or contact support.");
          setIsLoading(false);  // Stop loading on error
        });
    }
      return (
        <div className="po-error-box">
          <div className="try-again"> <b>Please try again by clicking on the <div className="refresh-system">"Refresh System" </div>button below.</b></div>
          <div className="steps-content">
            <div className="steps-title">
            Once the page has refreshed, follow these steps to access the PO requests:
            </div>
            <div className="steps">
            1. User profile image &gt; User System Profile. <br/>
            2. Click on the <b>PO tile plus</b> icon to add it to your favorites. <br/>
            3. Access the PO system through the <b>left panel</b>.
            </div>
  
          </div>
          
          <div className="action-container">
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <button onClick={resetPOCacheAPI} className="refresh-system-button">
              Refresh System
            </button>
          )}
          </div>
          <div className="not-working-text">
          <b>Still Not Working?</b> Just raise an incident using our &nbsp; 
            <div className="virtual-assistant">
              "Virtual Assistant" <img className="bot-icon" src={"./AppLoader/bot-icon-popup.svg"}/>
              &nbsp;
            </div>
            and we'll assist you further.
            
          </div>
          <Divider
              style={{ marginTop: "10px", width: "100%" }}
              variant="fullWidth"
            />
        </div>
      );
    }

  function poAlertDialogSystemAccess(){
    if (
      id &&
      pendingApprovalsStatus === RequestStatus.Success &&
      sessionStorage.getItem("ITTAccess") == "false"
    ) {
      return (
        <>
          <AlertDialog
          title={"Error in Loading"}
          content={getAlertContentUI()}
          centerOkButtontext="OK"
          handleOkButton={handlePOAlertOk}
        />
        </>
      );
    }
    return <></>;
  }

  function handlePOAlertOk() {
    sessionStorage.removeItem("ITTAccess");
    const domain = window.location.host;
    const link = document.createElement("a");
    link.href = "https://" + domain + "/#/po";
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  }

  function handleDownload(e: any): void {
    const link = document.createElement("a");
    link.href =
      "https://stapprovebuddyapidev.blob.core.windows.net/documents/Create%20and%20Submit%20a%20My%20Access%20User%20Request%20QRG.pdf";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
    sessionStorage.removeItem("ITTAccess");
  }

  function actionContainer(){
    if (props.getActionsUI) {
      return (
        <div className="action-container">
          {props.getActionsUI(true, multiSelectedApprovals as IPO[])}
        </div>
      );
    }
    return <></>;
  }
}
