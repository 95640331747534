import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { SWPSPage } from "Components2/SWPS/SWPSPage/SWPSPage";
import { Switch } from "react-router-dom";
import { AppHeader } from "Components2/AppHeader/AppHeader";
import { AppSidebar } from "Components2/AppSidebar/AppSidebar";
import { SidebarAppContent } from "Components2/SidebarAppContent/SidebarAppContent";
import { AppSplashScreen } from "Components2/AppSplashScreen/AppSplashScreen";
import "./Approvebuddy2.scss";
import VariablesJson from "./configVariables.json";
import { Constants } from "common/constants";
import { ScreenLoader } from "Components2/ScreenLoader/ScreenLoader";
import { NewApprovalOperationsSelector } from "Components2/NewApprovalOperationsSelector/NewApprovalOperationsSelector";
import {
  ApprovalOperationKeysToShowInSidebarSelector,
  ApprovalOperationsMapSelector,
  ApprovalSystemsMapSelector,
  GetUserProfile,
  UserProfileResponseSelector,
} from "Store/UserSystemProfile/UserSystemSlice";
import {
  DefaultPathSelector,
  PathsSelector,
  SetRoutes,
} from "Store/Routes/RoutesSlice";
import { useAppDispatch } from "Store/store";
import { POPage } from "Components2/PO/POPage/POPage";
import { TimeSheetPage } from "Components2/TimeSheet/TimeSheetPage/TimeSheetPage";
import { EJourneyPage } from "Components2/EJourney/EJourneyPage/EJourneyPage";
import { PRPage } from "Components2/PR/PRPage/PRPage";
import { QuestPage } from "Components2/Quest/QuestPage/QuestPage";
import { JRAPage } from "Components2/JRA/JRAPage/JRAPage";
import { DLPPage } from "Components2/DLP/DLPPage/DLPPage";
import { PreTripPage } from "Components2/PreTrip/PreTripPage/PreTripPage";
import { PWADialog } from "Components2/PWADialog/PWADialog";
import { WatershedPage } from "Components2/Watershed/WatershedPage/WatershedPage";
import { EQuotePage } from "Components2/EQuote/EQuotePage/EQuotePage";
import AlertDialog from "Components2/Alert/AlertDialog";
import { SystemSelectionService } from "Services";
import {
  GetPendingApprovals,
  SearchQuerySelector,
  setSearchQuery,
  setSelectedApproval,
} from "Store/PO/POSlice";
import { SetSplashProgressBar } from "Store/Common/CommonSlice";
import Utility from "common/utilities";
import Ticker from "Components2/Ticker/Ticker";
import { SRMPage } from "./Components2/SRM/SRMPage/SRMPage";
import { CircularProgress, Divider } from "@material-ui/core";
import { EClaimsPage } from "Components2/EClaims/EClaimsPage/EClaimsPage";

export function Approvebuddy2({ InstallEvent }: any) {
  let searchQuery = useSelector(SearchQuerySelector);
  let approvalOperationsMap = useSelector(ApprovalOperationsMapSelector);
  let approvalSystemsMaps = useSelector(ApprovalSystemsMapSelector);
  let userProfileResponse = useSelector(UserProfileResponseSelector);
  const [systemSelectOpen, setsystemSelectUserSystemOpen] = useState(false);
  const modalOpenedRef = useRef(false);
  const [installStatus, setInstallStatus] = useState<boolean>(false);
  const [closedFromPrompt, setClosedFromPrompt] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);  // State to manage loading indicator
  useEffect(() => {
    if (InstallEvent && closedFromPrompt ) {
      setInstallStatus(true);
    }
  }, [InstallEvent]);

  const installHandler = (result: boolean) => {
    setInstallStatus(false);
    if (result && InstallEvent) {
      InstallEvent.prompt();
      InstallEvent.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === "accepted") {
          // user accepted the install prompt
        } else {
          // user rejected the install prompt
          setClosedFromPrompt(false);
        }
      });
    }
  };

  let [routes, setRoutes] = useState<Route[]>([]);
  let [defaultRoute, setDefaultRoute] = useState<Route | null>(null);

  let paths = useSelector(PathsSelector);
  let defaultPath = useSelector(DefaultPathSelector);

  let approvalOperationKeysToShowInSidebar = useSelector(
    ApprovalOperationKeysToShowInSidebarSelector
  );
  let appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(GetUserProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appDispatch(setSelectedApproval(null));
    if (searchQuery) {
      appDispatch(GetPendingApprovals());
      appDispatch(SetSplashProgressBar(false));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (
      approvalOperationKeysToShowInSidebar.length === 0 ||
      Object.keys(approvalOperationsMap).length === 0 ||
      Object.keys(approvalSystemsMaps).length === 0
    ) {
      return;
    }

    appDispatch(SetRoutes());
  }, [
    approvalOperationKeysToShowInSidebar,
    approvalOperationsMap,
    approvalSystemsMaps,
  ]);

  useEffect(() => {
    if (defaultPath) {
      setDefaultRoute(GetDefaultRoute(defaultPath));
    }
  }, [defaultPath]);

  useEffect(() => {
    setupRoutes(paths);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths]);

  const selectedCount =
    Utility.calculateSelectedSystemsCount(approvalSystemsMaps);

  useEffect(() => {
    if (selectedCount > 5 && !modalOpenedRef.current) {
      setsystemSelectUserSystemOpen(true);
      modalOpenedRef.current = true; // Mark the modal as opened
    }
  }, [selectedCount]);

  return (
    <div className="approve-buddy-2">
      <AppHeader
        InstallEvent={InstallEvent}
        systemSelectOpen={systemSelectOpen}
        setsystemSelectUserSystemOpen={setsystemSelectUserSystemOpen}
      />
      {/* <SnackBar
        open={VariablesJson.Variables.show_warning_message === "true"}
        message={VariablesJson.Variables.warning_message}
      /> */}
      {userProfileResponse && userProfileResponse?.isNewUser === true && (
        <PWADialog open={installStatus} onClose={installHandler} />)
      }
      

      <SidebarAppContent
        sidebarUI={<AppSidebar />}
        appContent={getAppContentUI()}
      />
      {/* Component responsible for the when new systems are available */}
      {<NewApprovalOperationsSelector />}
      {/* Screen loading */}
      <ScreenLoader />
      <AppSplashScreen />
    </div>
  );

  function handleDownload(e: any): void {
    sessionStorage.removeItem("ITTAccess");
    const link = document.createElement("a");
    link.href = VariablesJson.Variables.pounauthorizemanual;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
    setTimeout(() => {
      defaultpathOnError();
    }, 800);
  }

  function defaultpathOnError() {
    sessionStorage.removeItem("ITTAccess");
    const domain = window.location.host;
    const link = document.createElement("a");
    link.href = "https://" + domain + "/#/po";
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  }

  function getParamValue(url: string, paramName: string) {
    // Remove any preceding hash mark (#) from the URL
    url = url.split("#")[1];
    // Split the URL by the question mark (?) to get the query string
    const queryString = url.split("?")[1];
    // If no query string is present, return null
    if (!queryString) {
      return null;
    }
    // Split the query string by the ampersand (&) to get the parameters
    const parameters = queryString.split("&");
    // Iterate over the parameters to find the specified parameter and its value
    for (const parameter of parameters) {
      const [rawName, rawValue] = parameter.split("=");
      const paramNameFromURL = decodeURIComponent(rawName);

      // If the parameter name matches the specified name, return its value
      if (paramNameFromURL === paramName) {
        return decodeURIComponent(rawValue);
      }
    }

    // If the parameter is not found, return null
    return null;
  }


  function getAppContentUI() {
    return (
      <div className="app-content">
        <div className="app-content-ticker">
          <Ticker textArray={""} />
        </div>
        <Switch>
          {routes.map((r, index) => {
            return (
              <Route
                key={Utility.createUniqueId(r.props.path, index)}
                path={r.props.path}
                exact={r.props.exact}
                render={r.props.render}
              />
            );
          })}
          {defaultpath()}
        </Switch>
      </div>
    );
  }

  function getAlertContentUI() {

  function resetPOCacheAPI() {
    setIsLoading(true);  // Start loading
    let systemservice = SystemSelectionService.getInstance();
    systemservice
      .resetSpecificSystemCache("PO", "ITT")
      .then((_res) => {
        const paramValue = getParamValue(window.location.href, "ponumber");
        if (paramValue) {
          appDispatch(setSearchQuery(paramValue));
        }
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((error) => {
        console.error("Failed to reset cache: ", error);
        alert("Failed to refresh the system. Please try again or contact support.");
        setIsLoading(false);  // Stop loading on error
      });
  }
    return (
      <div className="po-error-box">
        <div className="try-again"> <b>Please try again by clicking on the <div className="refresh-system">"Refresh System" </div>button below.</b></div>
        <div className="steps-content">
          <div className="steps-title">
          Once the page has refreshed, follow these steps to access the PO requests:
          </div>
          <div className="steps">
          1. User profile image &gt; User System Profile. <br/>
          2. Click on the <b>PO tile plus</b> icon to add it to your favorites. <br/>
          3. Access the PO system through the <b>left panel</b>.
          </div>

        </div>
        
        <div className="action-container">
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <button onClick={resetPOCacheAPI} className="refresh-system-button">
            Refresh System
          </button>
        )}
        </div>
        <div className="not-working-text">
        <b>Still Not Working?</b> Just raise an incident using our &nbsp; 
          <div className="virtual-assistant">
            "Virtual Assistant" <img className="bot-icon" src={"./AppLoader/bot-icon-popup.svg"}/>
            &nbsp;
          </div>
          and we'll assist you further.
          
        </div>
        <Divider
            style={{ marginTop: "10px", width: "100%" }}
            variant="fullWidth"
          />
      </div>
    );
  }

  function defaultpath() {
    if (
      approvalOperationKeysToShowInSidebar &&
      (approvalOperationKeysToShowInSidebar).length > 0 &&
      window.location.href.includes("ponumber") &&
      sessionStorage.getItem("ITTAccess") == "false"
    ) {
      return (
        <AlertDialog
          title={"Error in Loading"}
          content={getAlertContentUI()}
          centerOkButtontext="OK"
          handleOkButton={defaultpathOnError}
        />
      );
    } else {
      return (
        defaultRoute && (
          <Route
            path={defaultRoute.props.path}
            exact={defaultRoute.props.exact}
            render={defaultRoute.props.render}
          />
        )
      );
    }
  }

  function GetDefaultRoute(_route: string): Route | null {
    return new Route({
      path: "*",
      render: (props: any) => {
        return <Redirect to={_route ? _route : ""} />;
      },
    });
  }

  function getMatchingRoute(_route: string): Route | null {
    let matchingRoute: Route | null = null;

    switch (_route.toLowerCase()) {
      case Constants.SWPSSystemId:
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <SWPSPage/>
            );
          },
        });
        break;

      case "po":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <POPage
              ></POPage>
            );
          },
        });
        break;

      case "timesheet":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <TimeSheetPage
              ></TimeSheetPage>
            );
          },
        });
        break;

      case "questchange":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <QuestPage
              ></QuestPage>
            );
          },
        });
        break;

      case "travel":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <PreTripPage
              ></PreTripPage>
            );
          },
        });
        break;

      case "trip":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <EJourneyPage
              ></EJourneyPage>
            );
          },
        });
        break;

      case "fdp":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <JRAPage
              ></JRAPage>
            );
          },
        });
        break;

      case "plan":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <DLPPage
              ></DLPPage>
            );
          },
        });
        break;

      case "pr":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <PRPage
              ></PRPage>
            );
          },
        });
        break;
      case "watershed":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <WatershedPage
              ></WatershedPage>
            );
          },
        });
        break;
      case "quote":
        matchingRoute = new Route({
          exact: true,
          path: "/" + _route.toLowerCase(),
          render: (props: any) => {
            return (
              <EQuotePage
              ></EQuotePage>
            );
          },
        });
        break;
        case "srm":
          matchingRoute = new Route({
            exact: true,
            path: "/" + _route.toLowerCase(),
            render: (props: any) => {
              return (
                <SRMPage
                ></SRMPage>
              );
            },
          });
          break;
      case "claim":
          matchingRoute = new Route({
            exact: true,
            path: "/" + _route.toLowerCase(),
            render: (props: any) => {
              return (
                <EClaimsPage
                ></EClaimsPage>
              );
            },
          });
          break;
      }
    return matchingRoute;
  }

  function setupRoutes(_routes: string[]) {
    let result: Route[] = [];

    _routes.forEach((r) => {
      let _route = getMatchingRoute(r);
      if (_route) {
        result.push(_route);
      } else {
        console.error("Not found matching route for : " + JSON.stringify(r));
      }
    });

    setRoutes(result);
  }
}
