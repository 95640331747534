import React from "react";
import { IEClaims } from "../../../Model/ServerResponse";
import { IKeyValue } from "../../../Model/UI";
import "./EClaimsDetailKeyValueList.scss";
import { KeyValueList } from "../../KeyValueList/KeyValueList";

export interface IEClaimsDetailKeyValueListProp {
    eClaim: IEClaims;
}

export function EClaimsDetailKeyValueList(props: IEClaimsDetailKeyValueListProp) {
    function getHeaderTableUI() {
        let _data = getKeyValueListData();
        return (
            <div className="eclaims-header-key-value">
                <KeyValueList data={_data} />
            </div>
        );
    }

    function createKeyValue(dataArray: IKeyValue[], key: string, value: string, seperator: string) {
        if (value) dataArray.push({ _key: key, value, seperator });
    }

    function getKeyValueListData(): IKeyValue[] {
        let dataArray: IKeyValue[] = [];
        createKeyValue(dataArray, "Policy", props.eClaim.claimType, ":");
        createKeyValue(dataArray, "Group Name", props.eClaim.groupType, ":");
        createKeyValue(dataArray, "Business Purpose", props.eClaim.purpose, ":");
        createKeyValue(dataArray, "Company", props.eClaim.company, ":");
        createKeyValue(dataArray, "Report Total", props.eClaim.totalClaimedAmount + ' ' +props.eClaim.totalClaimedCurrency, ":");
        createKeyValue(dataArray, "Amount Due Employee", props.eClaim.amountDueEmployee + ' ' +props.eClaim.amountDueCurrency, ":");

        return dataArray;
    }

    return getHeaderTableUI();
}
