import Utility from "common/utilities";
import { IEClaims } from "Model/ServerResponse";
import { IKeyValue, ISystemCardBaseProp } from "Model/UI";
import React from "react";
import { Card9 } from "../../CardTemplates/Card9/Card9";
import "./EClaimsCard.scss";
import { Constants } from "common/constants";

export interface IEClaimsCardProp extends ISystemCardBaseProp {
  eclaims: IEClaims;
}

export function EClaimsCard(props: IEClaimsCardProp) {

  function getCardUI() {
    return (
      <Card9
        label1={props.eclaims.approvalSystemId}
        label2={props.eclaims.title}
        label3={props.eclaims.claimType}
        label4={Utility.formatDate(props.eclaims.submitDate, Constants.DD_MM_YY)}
        supportMulti={props.supportMulti}
        multiSelectUI={props.multiSelectUI}
        keyValue1={{_key: props.eclaims.totalClaimedAmount?.toString(),value: props.eclaims.totalClaimedCurrency,}}
        keyValue2={{ _key: "Business Purpose", value: props.eclaims.purpose?.toString() }}
        keyValue3={{ _key: "Items", value: props.eclaims.items?.toString() }}
        userDetails={{
          userId: props.eclaims.requestorID,
          userCardName: props.eclaims.requestor,
          userOrganization: props.eclaims.requestorJobRole,
        }}
        className="eclaims-card"
      ></Card9>
    );
  }

  return getCardUI();
}
