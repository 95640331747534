import React from "react";
import { IAttachmentData, IEClaims, IEClaimsTab } from "../../../Model/ServerResponse";
import { ITabData } from "../../../Model/UI/ITabData";
import { DetailTabsComponent } from "../../DetailTabsComponent/DetailTabsComponent";
import EClaimsApprovers from "../EClaimsApprovers/EClaimsApprovers";
import { EClaimsDetails } from "../EClaimsDetails/EClaimsDetails";
import { EC_APPROVERS_TAB_ID, EC_ITEMS_TAB_ID, EC_ATTACHMENT_TAB_ID } from "../EClaimsUtil";
import { EClaimsAttachments } from "../EClaimsAttachments/EClaimsAttachments";
export interface IEClaimsTabsProp {
  eClaim: IEClaims;
  eClaimAttachment: IAttachmentData | undefined;
  eClaimAttachmentFunction : (filePath: string, fileID: string) => Promise<IAttachmentData>;
}

export function EClaimsTabs(props: IEClaimsTabsProp) {
  function getTabsUI() {
    const data = getTabsData(props.eClaim.tabs);
    return <DetailTabsComponent tabs={data}></DetailTabsComponent>;
  }

  function getTabsData(tabs: IEClaimsTab[]): ITabData[] {
    let tdata: ITabData[] = [];

    tabs.forEach((tab: IEClaimsTab) => {
      let tabInfo: ITabData = {
        tabId: "",
        title: tab.title,
        imageSrc: "",
        // @ts-ignore: Unreachable code error
        count: tab.data.length,
      };

      // By id
      switch (tab.tabId) {
        case EC_ITEMS_TAB_ID:
          tabInfo.tabId = EC_ITEMS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/details_icn.svg";
          tabInfo.getTabUI = ItemsUI;
          break;
        case EC_APPROVERS_TAB_ID:
          tabInfo.tabId = EC_APPROVERS_TAB_ID;
          tabInfo.imageSrc = "./DetailTab/approvericn.svg";
          tabInfo.getTabUI = ApproversUI;
          break;

        case EC_ATTACHMENT_TAB_ID:
            tabInfo.tabId = EC_ATTACHMENT_TAB_ID;
            tabInfo.imageSrc = "./DetailTab/attachment_icn.svg";
            tabInfo.getTabUI = AttachmentUI;
            break;
        default:
          break;
      }
      tdata.push(tabInfo);
    });
    return tdata;
  }

  function ItemsUI() {
    
    return <EClaimsDetails eClaimsData={props.eClaim} />;
  }

  function ApproversUI() {
    let _eClaimsApproversData = props.eClaim.tabs.find(
      (td) => td.tabId === EC_APPROVERS_TAB_ID
    );
    return <EClaimsApprovers eClaimsApproversTabData={_eClaimsApproversData} />;
  }

  function AttachmentUI() {
    console.log("Attachment")
    return <EClaimsAttachments eClaim={props.eClaim} eClaimAttachmentFunction={props.eClaimAttachmentFunction}  eClaimAttachment = {props.eClaimAttachment}  />;
  }
  
    return getTabsUI();

}
