import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { IDefaultUIProp } from "../../../Model/UI";
import * as React from "react";
import "./EClaimsModal.scss";
import { IAttachmentData } from "Model/ServerResponse";
import { useEffect, useRef, useState } from "react";
import { SessionStorageService } from "Services/SessionStorageService";
import { isAndroid, isIOS } from "react-device-detect";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export interface IAttachmentModalProp extends IDefaultUIProp {
  onClose?: (e: any) => void;
  open: boolean;
  selectedFilename: string;
  selectedFileID: string;
  eClaimAttachment?: IAttachmentData;
  isEClaim?: boolean;
  getAttachmentData: (
    fileID: string,
    fileName: string
  ) => Promise<IAttachmentData>;
}

export function EClaimsModal(props: IAttachmentModalProp) {
  const [attachmentDataLocal, setattachmentData] =
    useState<IAttachmentData | null>(null);

  async function getAttachmentData(_fileName: string, _fileID: string) {
    let fileNameToUse = _fileName;
    let fileIDToUse = _fileID;
    if (props.isEClaim) {
      return;
    }
    let result = await props.getAttachmentData(fileNameToUse, fileIDToUse);
    setattachmentData(result);
  }

  const [pdfDownloadable, setpdfDownloadable] = React.useState(true);
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);  const [open, setOpen] = React.useState(false);
  const [numPages, setNumPages] = useState(0); // Add state for numPages

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      getAttachmentData(props.selectedFilename, props.selectedFileID);
      requestAnimationFrame(() => {
        if (pdfContainerRef.current) {
          setWidth(pdfContainerRef.current.offsetWidth);
        }
      });
    } else {
      setattachmentData(null);
      setWidth(0); // Reset width when modal is closed

    }
  }, [props.open]);

  return (
    <Dialog
      maxWidth="md"
      data-testid="eclaims-attachment-modal"
      className="eclaims-attachment-modal"
      open={open}
      onClose={handleClose}
    >
      <div className="attachment-header">
        <p className="attachment-title">{props.selectedFileID}</p>
        <div className="spacer"></div>
        <Button className="download-btn" onClick={onDownloadFile}>
          Download
        </Button>
        <IconButton
          data-testid="close"
          className="close-btn"
          onClick={handleClose}
        >
          <CancelIcon />
        </IconButton>
      </div>
      <Divider className="divider-1" variant="fullWidth" />
      <DialogContent className="attachment-content">
        {getAttachmentUI()}
      </DialogContent>
    </Dialog>
  );

  function handleClose(e: any) {
    setOpen(false);
    if (props.onClose) {
      props.onClose(e);
    }
  }

  function onDownloadFile() {
    if (
      SessionStorageService.getInstance().IsTeamsApp &&
      (isAndroid || isIOS)
    ) {
      alert("Downloading file is not supported on this device");
    } else {
      let attachmentData = props.eClaimAttachment;
      const contentType = attachmentData?.fileType;
      const b64Data = attachmentData?.fileData;
      const fileName = props.selectedFileID;

  
      if (b64Data) {
        // Decode base64 data and create a Blob
        const byteCharacters = atob(b64Data);
        const byteArrays: Uint8Array[] = []; // Explicitly type the array
  
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
  
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
  
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
  
        const blob = new Blob(byteArrays, { type: contentType });
  
        // Detect if the browser is Safari
        const isSafari =
          /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
        if (isIOS || isSafari) {
          // Use Blob and createObjectURL for Safari/iOS
          const url = window.URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
        } else {
          // For other browsers, use data URLs
          const downloadHref =
            "data:" + contentType + ";base64," + b64Data;
          const downloadLink = document.createElement("a");
          downloadLink.href = downloadHref;
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        alert("Please wait before the file loads");
      }
    }
  }
  

  function getAttachmentUI() {
    // switch case for file type and return the appropriate UI
    let attachmentData = props.eClaimAttachment ?? attachmentDataLocal;
    const filePath = `data:${attachmentData?.fileType};base64,${attachmentData?.fileData}`;
    switch (attachmentData?.fileType) {
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
        return (
          <img
            data-testid="img-frame"
            className="img-frame"
            src={filePath}
            alt=""
          />
        );
      case "application/pdf":
        return loadIFrame(filePath);
      case "video/mp4":
      case "video/avi":
      case "audio/mp3":
      case "audio/wav":
      case "audio/ogg":
      case "audio/flac":
      case "audio/aac":
      case "audio/mpeg":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
      case "application/vnd.ms-excel":
      case "application/yaml":
      case "text/html":
      case "application/json":
      case "application/javascript":
      case "text/csv":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/octet-stream":
      case "application/msexcel":
      case "text/htm":
      case "plain/text":
      case "application/xml":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
      case "application/vnd.ms-word.document.macroEnabled.12":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.openxmlformats-officedocument.presentationml.template":
      case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      case "application/vnd.openxmlformats-officedocument.presentationml.slide":
      case "application/rtf":
      case "application/zip":
      case "image/gif":
      case "image/bmp":
      case "image/x-pcx":
      case "image/vnd.adobe.photoshop":
      case "text/plain":
        return (
          <div data-testid="no-support" className="no-support">
            <div className="not-supported-text">
              <img
                className="file-image"
                alt=""
                src="./Attachment/File_icn.svg"
              ></img>
              <div className="not-supported">
                <p>File not supported for preview</p>
                <p>Please download the file to view</p>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <CircularProgress
            data-testid="circular-progress"
            className="circular-progress"
          />
        );
    }
  }

  function loadIFrame(filePath: any) {
    function getScaleValue() {
        const containerWidth = window.innerWidth * 0.8; // Set scale based on 80% of the modal width
        const scale = containerWidth / 1000; // Assume the base width for scaling is 1000px
        return scale > 1 ? 1 : scale; // Ensure the scale does not exceed 1 (original size)
      }

      const width =
              (document.querySelector('.pdf-container') as HTMLElement)?.offsetWidth;
      
    if (pdfDownloadable) {
      return (
       
        <div className="pdf-container" ref={pdfContainerRef}>
          {width > 0 && (
            <Document
              className="pdf-iframe"
              file={filePath}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              loading={<CircularProgress className="circular-progress" />}
              error={<p>Failed to load PDF.</p>}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  className="pdf-iframe"
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={width}
                />
              ))}
            </Document>
          )}
        </div>
      );
    } else {
      return (
        <div className="no-support">
          <div className="not-supported-text">
            <img
              className="file-image"
              alt=""
              src="./Attachment/File_icn.svg"
            ></img>
            <p className="not-supported">File not supported for preview</p>
            <p>Please download the file to view</p>
          </div>
        </div>
      );
    }
  }
}
