import { ISystemDetailsCardBaseProp } from "Model/UI";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  GetApprovalDetails,
  MultiSelectedApprovalsSelector,
  PendingApprovalsStatusSelector,
  SelectedApprovalDetailErrorSelector,
  SelectedApprovalDetailsSelector,
  SelectedApprovalDetailStatusSelector,
  SelectedApprovalSelector,
  setSelectedApprovalDetails,
} from "Store/EClaims/EClaimsSlice";
import "./EClaimsDetailsCard.scss";
import { useAppDispatch } from "Store/store";
import { RequestStatus } from "Model/Enums";
import { Message1 } from "Components2/MessageTemplates/Message1/Message1";
import { DetailSkeleton1 } from "Components2/Skeletons/DetailSkeletons/DetailSkeleton1/DetailSkeleton1";
import { IAttachmentData, IEClaims, IEClaimsAttachmentData, IServerResponse } from "Model/ServerResponse";
import { EClaimsHeader } from "../EClaimsHeader/EClaimsHeader";
import { EClaimsDetailKeyValueList } from "../EClaimsDetailKeyValueList/EClaimsDetailKeyValueList";
import { EClaimsTabs } from "../EClaimsTabs/EClaimsTabs";
import { AttachmentService } from "Services";
import utilities from "common/utilities";

export interface IEClaimsDetailsCardProp<T>
  extends ISystemDetailsCardBaseProp<T> {}

export function EClaimsDetailsCard(props: IEClaimsDetailsCardProp<IEClaims>) {
  let appDispatch = useAppDispatch();

  let selectedApproval = useSelector(SelectedApprovalSelector);
  let multiSelectedApprovals = useSelector(MultiSelectedApprovalsSelector);

  let selectedApprovalDetails = useSelector(SelectedApprovalDetailsSelector);
  let selectedApprovalDetailsStatus = useSelector(
    SelectedApprovalDetailStatusSelector
  );
  let selectedApprovalDetailsError = useSelector(
    SelectedApprovalDetailErrorSelector
  );

  let pendingApprovalsStatus = useSelector(PendingApprovalsStatusSelector);
  let attachmentService = AttachmentService.getInstance();

  let [fileAttachmentData, setFileAttachmentData] = useState<IAttachmentData>();


  async function getAttachmentData(eclaimsDataId: string, fileID: string): Promise<IAttachmentData> {
    return attachmentService
      .getAttachmentData(
        `api/approvals/${"eClaims"}/${"Claim"}/${eclaimsDataId}/attachments/${fileID}`
      )
      .then((attachmentResponse: IServerResponse<IAttachmentData>) => {
        return attachmentResponse.payload!;
      });
  }

  //Re-render
  React.useEffect(() => {
    appDispatch(setSelectedApprovalDetails(null));

    if (selectedApproval) {
      appDispatch(GetApprovalDetails());
    }
  }, [selectedApproval]);

    //Re-render
    React.useEffect(() => {
      if (selectedApprovalDetails !== null) {
        let eclaimsData = selectedApprovalDetails as IEClaims;
        let attachmentsData = (selectedApprovalDetails as IEClaims).tabs.find(
          (td) => td.tabId === "eClaims-Attachments"
        );
        let attachmentData = attachmentsData?.data as IEClaimsAttachmentData[];
        let FileID = attachmentData[0]?.id;
        getAttachmentData(eclaimsData.id, FileID).then((data) => {
          // Handle the attachment data here
          setFileAttachmentData(data);
        });
      }
    }, [selectedApprovalDetails]);



  return <div className="eclaims-details-card">{detailsUI()}</div>;

  function detailsUI() {
    if (
      selectedApprovalDetailsStatus === RequestStatus.Loading ||
      (pendingApprovalsStatus === RequestStatus.Loading && !selectedApproval)
    ) {
      return loadingUI();
    } else if (selectedApprovalDetailsStatus === RequestStatus.Failed) {
      return errorUI();
    } else if (!selectedApproval) {
      return emptyIndicatorUI();
    }

    if (selectedApprovalDetails) {
      return (
        <>
          <div className="eclaims-details-header">
            <EClaimsHeader
              eclaims={selectedApprovalDetails as IEClaims}
            ></EClaimsHeader>
            <div className="dividerDiv"></div>
          </div>
          <div className="eclaims-details-content">
            <EClaimsDetailKeyValueList
              eClaim={selectedApprovalDetails as IEClaims}
            ></EClaimsDetailKeyValueList>
            <EClaimsTabs
              eClaim={selectedApprovalDetails as IEClaims}
              eClaimAttachment = {fileAttachmentData}
              eClaimAttachmentFunction = {getAttachmentData}
            ></EClaimsTabs>
          </div>
          {actionContainerUI()}
        </>
      );
    } else {
      return <></>;
    }
  }

  function loadingUI() {
    return <DetailSkeleton1 />;
  }

  function emptyIndicatorUI() {
    return (
      <div className="message-container">
        <Message1
          title="No details to show."
          imgSrc="CardAssets/NoPendingRequests.svg"
        ></Message1>
      </div>
    );
  }

  function errorUI() {
    if (selectedApprovalDetailsError) {
      return (
        <div className="message-container">
          <Message1
            title={selectedApprovalDetailsError.message}
            subTitle={selectedApprovalDetailsError.description}
            imgSrc={"CardAssets/error.svg"}
          ></Message1>
        </div>
      );
    }
    return <></>;
  }

  function actionContainerUI() {
    if (selectedApprovalDetails && multiSelectedApprovals.length === 0) {
      return (
        <div className="action-container">
          {props.getActionsUI(false, [selectedApprovalDetails as IEClaims])}
        </div>
      );
    }
    return <></>;
  }
}
